import React from "react";
import "./InvitedSpeakers.css";
import Navigation2 from "../Navigation2/Navigation2";
import Footer2 from "../FooterForOthers/Footer2";
import ann from "../../assets/ann.jpg";
import jan from "../../assets/jan.jpg";

const InvitedSpeakers = () => {


  return (
    <div className="registration">
      <Navigation2 />
      <div className="pp-heading">
        <h1>Invited speakers</h1>
        

        <div className="invited-speakers">
          
            <>
              <div className="container cskplancolumn3">
                <div className="right-text-csk">
                  <img src={jan} alt="jan" />
                  <h4 id="#talkjan">Jan-Willem van de Meent</h4>
                </div>
                <div className="left-text-csk">
                  <h2 style={{textAlign: "center"}}>Wednesday 8 NOVEMBER</h2>
                  <h2>Title</h2>
                  <p>
                    What can AI do for science, and what can science do for AI?
                  </p>
                  <h2>Abstract</h2>
                  <p>
                    Recent advances in AI have shown that near-miraculous
                    examples of generalization can emerge when large models are
                    pre-trained on massive datasets. Beyond the horizon of these
                    highly visible breakthroughs, which have predominantly
                    focused on images and text, there lies a tremendous
                    potential for AI in domains where the data modalities and
                    prediction problems are much more diverse. In this talk, I
                    will discuss where I see opportunities to apply AI to the
                    modeling and design of physical systems in science and
                    engineering. In particular, I will discuss how programming
                    language design can help us accelerate development of models
                    for specific applications. I will discuss our work on
                    probabilistic programming, which enables application of
                    Bayesian inference methods to stochastic simulators, and the
                    development of inference programming, which train neural
                    networks to accelerate inference. I will also provide
                    perspectives on emerging problems in science that inform
                    methods development in AI, such as the methods for systems
                    described by differential equations, and methods for inverse
                    design.
                  </p>
                  <h2>Bio</h2>
                  <p>
                    Dr. Jan-Willem van de Meent is an Associate Professor
                    (Universitair Hoofddocent) at the University of Amsterdam.
                    He co-directs the AMLab with Max Welling and co-directs the
                    UvA Bosch Delta Lab with Theo Gevers. His work seeks to
                    understand what methods in AI have the potential to
                    generalize across diverse application domains. To this end
                    he combines work on the design of programming languages for
                    artificial intelligence with application-focused
                    collaborations in robotics, NLP, healthcare, neuroscience,
                    chemistry, fluid mechanics, and materials science. A key
                    component of his research is probabilistic programming. He
                    is one of the creators of Anglican, a probabilistic language
                    based on Clojure, and of Probabilistic Torch, a library for
                    deep generative models that extends PyTorch, and a co-author
                    of a book on probabilistic programming, a draft of which is
                    available on arXiv. He has served as a founding co-chair of
                    the international conference on probabilistic programming
                    (PROBPROG) and is currently serving as a program chair for
                    the international conference on artificial intelligence and
                    statistics (AISTATS). He was the recipient of an NWO Rubicon
                    Fellowship and of the NSF CAREER award.
                  </p>
                </div>
              </div>
              <div className="container cskplancolumn3">
                <div className="right-text-csk">
                
                  <img src={ann} alt="annnowe" />
                  <h4 >Ann Nowé</h4>
                </div>
                <div className="left-text-csk">
                <h2 style={{textAlign: "center"}}>Friday 10 NOVEMBER</h2>
                  <h2>Title</h2>
                  <p id='talkann'>
                    Trustworthy Reinforcement Learning, challenges and
                    opportunities
                  </p>
                  <h2>Abstract</h2>
                  <p>
                    The increasing power of Reinforcement Learning techniques,
                    in particular Deep RL, comes with an increase in complexity,
                    and the traditional convergence guarantees no longer hold.
                    Moreover, the policies learned express more complex
                    behaviour. While transparency and explainability of machine
                    learning models have recently received quite some attention,
                    this is much less explored in the context of Reinforcement
                    Learning. In this talk I will discuss the different aspects
                    to this problem and provide an overview of the state-of-art,
                    including some of our own work on policy distillation and
                    formal guarantees. I will also briefly touch upon fairness
                    aspects in Reinforcement Learning.
                  </p>
                  <h2>Bio</h2>
                  <p>
                    Ann Nowé is a professor of Computer Science at the Vrije
                    Universiteit Brussel (VUB) where she is heading the AIlab.
                    Her main research interest is Reinforcement Learning (RL)
                    including Multi-Agent RL and Multi-criteria RL. She strongly
                    believes in the interplay between theory and applications.
                    Her team developed novel algorithms which were tested in
                    domains such as smart grids, communication networks,
                    mechatronics and scheduling problems. Ann Nowé is a former
                    board member of EurAI and chairman of the BNVKI, and a
                    current board member of IFAAMAS. She was co-PC chair of
                    AAMAS’21 and general chair of ECAI’23 and EWRL’23. Recently
                    she was elected as EurAi fellow.{" "}
                  </p>
                </div>
              </div>
            </>
          

          
        </div>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </div>
  );
};

export default InvitedSpeakers;
