import React from "react";
import "../CallforPapers/CallforPapers.css";
import Navigation from "../Navigation/Navigation";
import Footer2 from "../FooterForOthers/Footer2";
const CallforPapers = () => {
  return (
    <div className="callforpapers">
      <Navigation />
      <div className="call-heading">
        <h1>Call For Papers: BNAIC/BeNeLearn 2023</h1>
        <p>
          BNAIC/BeNeLearn is the reference AI &amp; ML conference for Belgium,
          Netherlands &amp; Luxembourg. The combined 35th BNAIC and 32nd
          BeNeLearn conference will take place November 8-10 in Delft, the
          Netherlands. It is organized by the Delft University of Technology,
          under the auspices of the Benelux Association for Artificial
          Intelligence (BNVKI) and in cooperation with the Netherlands Research
          School for Information and Knowledge Systems (SIKS).
        </p>
        <h1>SUBMISSION INFORMATION</h1>
        <p>
          Researchers are invited to submit unpublished original research on all
          aspects of Artificial Intelligence and Machine Learning. Additionally,
          high-quality research results already published in international AI/ML
          conference proceedings or journals are also welcome for presentation
          at the conference, and will be published as extended abstracts.
        </p>
        <p>Four types of submissions are invited:</p>
        <p>
          ★ Type A: Regular papers Papers presenting original work that advances
          Artificial Intelligence and Machine Learning. Position and review
          papers are also welcome. These contributions should address a well-
          developed body of research, an important new area, or a promising new
          topic, and provide a big picture view. Type A papers can be long (up
          to 15 pages, excluding references and appendices) or short (at most 10
          pages, excluding references and appendices). Contributions will be
          reviewed on the basis of their overall quality and relevance.
        </p>
        <p>
          ★ Type B: Encore abstracts Abstracts of work published (or accepted)
          in an international conference or journal relating to AI/ML and
          closely related fields. These should have been accepted on or after
          1st September 2022. Authors are invited to submit the authors’ version
          of their officially published paper together with an abstract of at
          most 2 pages (excluding references). Authors are encouraged to include
          further results obtained after the publication in their abstract and
          presentation. Submissions will be judged based on their relevance to
          the conference. Authors may submit at most one type B paper of which
          they are the corresponding author.
        </p>
        <p>
          ★ Type C: Demonstration abstracts Proposals for demos should be
          submitted as a 2-page (excluding references) abstract. Demonstrations
          should also submit a short video illustrating the working of the
          system (not exceeding 15 minutes). Any system requirements should be
          mentioned in the submission. Demonstrations will be evaluated based on
          their originality and innovative character, the technology deployed,
          the purpose of the systems in interaction with users and/or other
          systems, and their economic and/or societal potential.
        </p>
        <p>
          ★ Type D: Thesis abstracts Bachelor and Master students are invited to
          submit a 2-page abstract (excluding references) of their completed
          AI/ML-related thesis. Supervisors should be listed. The thesis should
          have been accepted on or after 1st September 2022. Submissions will be
          judged based on their originality and relevance to the conference.
        </p>
        <p>
          Reviews will be single-blind. All submissions should include author
          names and their affiliations.
        </p>
        <h1>PRESENTATION AND PRIZES</h1>
        <p>
          Type A, B and D papers can be accepted for either oral or poster
          presentation.
        </p>
        <p>
          There will be prizes for the best paper (type A), best demonstration
          (type C), and best thesis (type D).
        </p>
        <h1>PRE- &amp; POST-PROCEEDINGS</h1>
        <p>
          Accepted contributions in all four categories will be included in the
          (non-archival) conference pre- proceedings, published open access by
          TU Delft OPEN Publishing. All contributions should be written in
          English, using the Springer CCIS/LNCS format (see{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.springer.com/gp/computer-
science/lncs/conference-proceedings-guidelines"
          >
            {" "}
            Information
          </a>
          ) and submitted electronically via EasyChair:{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.easychair.org/my/conference?conf=bnaicbenelearn2023"
          >
            EasyChair submit
          </a>
        </p>
        <p>
          Submission implies willingness of at least one author to register for
          BNAIC/BeNeLearn 2023 and present in person at the conference. For each
          paper, at least one author must register for the conference by the
          early registration deadline.
        </p>
        <p>
          Similar to previous years, we plan to organize post-proceedings in the
          Springer CCIS series. A selection of type A papers will be invited to
          submit to the post-proceedings{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.springer.com/series/7899"
          >
            (https://www.springer.com/series/7899).
          </a>
        </p>
        <h1>IMPORTANT DATES</h1>
        <p>
          All deadlines are at 23:59, Anywhere on Earth time zone; deadlines are
          firm.
        </p>
        <ul>
          <li style={{textDecoration: "line-through"}}>Abstract submission deadline: 30 August 2023 (extended)</li>
          <li style={{textDecoration: "line-through"}}>Paper submission deadline: 4 September 2023 (extended)</li>
          <li style={{textDecoration: "line-through"}}>Author notification: 4 October 2023</li>
          <li style={{textDecoration: "line-through"}}>Camera ready submission deadline: 24 October 2023</li>
          <li>Conference: 8-10 November 2023</li>
        </ul>
        <h1>TOPICS OF INTEREST</h1>
        <p>
          We invite contributions on any topic in the broad area of Artificial
          Intelligence and Machine Learning. In addition to fundamental work we
          encourage cross-domain and interdisciplinary work, as well as
          application of AI or ML-based techniques. A non-exhaustive list of
          topics includes:
        </p>
        <ul>
          <li>Bayesian Learning</li>
          <li>Case-based Learning</li>
          <li>Causal Learning</li>
          <li>Clustering</li>
          <li>Computational Creativity</li>
          <li>Computational Learning Theory</li>
          <li>Computational Models of Human Learning</li>
          <li>Data Mining &amp; Knowledge Discovery</li>
          <li>Data Visualisation</li>
          <li>Deep Learning</li>
          <li>Dimensionality Reduction</li>
          <li>Ensemble Methods</li>
          <li>Evaluation Frameworks</li>
          <li>Evolutionary Computation</li>
          <li>Graph Mining &amp; Social Network Analysis</li>
          <li>Inductive Logic Programming</li>
          <li>Interactive AI / Human-in-the-loop Methods and Systems</li>
          <li>Kernel Methods</li>
          <li>Learning and Ubiquitous Computing</li>
          <li>Learning in Multi-Agent Systems</li>
          <li>Learning from Big Data</li>
          <li>Learning from User Interactions</li>
          <li>Media Mining and Text Analytics</li>
          <li>ML Applications in Industry</li>
          <li>ML for Scientific Discovery</li>
          <li>Natural Language Processing / Natural Language Understanding</li>
          <li>Online Learning</li>
          <li>Pattern Mining</li>
          <li>Ranking / Preference Learning / Information Retrieval</li>
          <li>Reinforcement Learning</li>
          <li>Representation Learning</li>
          <li>Robot Learning</li>
          <li>Social Networks</li>
          <li>Speech Recognition</li>
          <li>Structured Output Learning</li>
          <li>Time series modelling &amp; prediction</li>
          <li>Transfer and Adversarial Learning</li>
        </ul>
      </div>
      <div className="footer-m">
        <Footer2 />
      </div>
    </div>
  );
};

export default CallforPapers;
