import React from "react";
import "./Industrial.css";
import Navigation2 from "../Navigation2/Navigation2";
import { GoLocation } from "react-icons/go";
import { BsCalendarDate } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import Footer2 from "../FooterForOthers/Footer2";

const Industrial = () => {
  const ind = [
    {
      name: "Ben Kuzey: FrieslandCampina",
    },
    {
      name: "Yves Peirsman: Deontic ",
    },
    {
      name: "Vlado Nedovic:  Azimut Zero (NL)",
    },
    {
      name: "Pieter van Hertum: ASML ",
    },
    {
      name: "Maaike de Boer: TNO ",
    },
    {
      name: "Wouter Kool: ORTEC",
    },
  ];

  const data = [
    {
      time: " 15:30-16:00",
      session: "Coffee + Demos + Stands",
    },
    {
      time: "16:00-17:00",
      session:
        "Panel: “AI in the scientific world and industry: opportunities and challenges”",
    },
    {
      time: "17:00-17:30",
      session: "Networking + Demos + Stands”",
    },
  ];

  const talks = [
    {
      name: "Carlo Lancia (ASML)",
      task: "Machine learning challenges in diagnosing lithography machines: high dimensionality, complexity and interpretability",
    },
    {
      name: "Wouter Kool (ORTEC)",
      task: "Machine learning @ ORTEC: learning to optimize and learning what to optimize",
    },
    {
      name: "Kylian Kropf (Dyflexis)",
      task: "Supporting non-expert users in modelling and understanding combinatorial optimisation / operation research.",
    },
  ];

  return (
    <>
      <div className="industrial">
        <Navigation2 />
        <div className="ind-heading">
          <h2>Industrial Session during BNAIC/BeNeLearn 2023 </h2>
          <p>(Open to everyone)</p>
          <h4>Moderator: Sophia Katrenko</h4>

          <div className="r-icons industrial">
            <p>
              {" "}
              <BsCalendarDate /> <b>November 8</b>
            </p>
            <p>
              {" "}
              <BiTimeFive />
              <b>15:30 - 17:30 </b>
            </p>
            <a
              href="https://www.google.com/maps/place/NEXT+Delft/@51.9925841,4.3847995,15z/data=!4m6!3m5!1s0x47c5cb22c8ca74af:0xe5b621f101ab3d71!8m2!3d51.9925841!4d4.3847995!16s%2Fg%2F11rjt2g57t?entry=ttu"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <GoLocation /> {""} Next Delft{" "}
            </a>
          </div>

          <div className="">
            <table className="table table-bordered table-dark table-sm ind-table">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Session</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.time}</td>
                    <td>{item.session}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="table table-bordered table-dark ind-table">
              <thead>
                <tr>
                  <th>Panelists</th>
                </tr>
              </thead>
              <tbody>
                {ind.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h2 style={{marginTop: "100px"}}>Industry talks</h2>
            <div className="r-icons industrial">
              <p>
                {" "}
                <BsCalendarDate /> <b>November 9</b>
              </p>
              <p>
                {" "}
                <BiTimeFive />
                <b> 11:00 - 12:00</b>
              </p>
            </div>

            <table className="table table-bordered table-dark ind-table">
              <thead>
                <tr>
                  <th>Authors</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody>
                {talks.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.task}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </>
  );
};

export default Industrial;
