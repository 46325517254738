import React from "react";
import "./PosterPitches.css";
import { useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import Navigation2 from "../Navigation2/Navigation2";
import Footer2 from "../FooterForOthers/Footer2";
const PosterPitches = () => {
  const [selectedDate, setselectedDate] = useState("FRI 10 NOV");

  const handleDateClick = (date) => {
    setselectedDate(date);
  };

  return (
    <div className="registration">
      <Navigation2 />
      <div className="pp-heading">
        <h2>Poster pitches</h2>
        <div className="pr-buttons">
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("WED 8 NOV")}
          >
            <b>WED 8 NOV</b>
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("THU 9 NOV")}
          >
            <b>THU 9 NOV</b>
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("FRI 10 NOV")}
          >
            <b>FRI 10 NOV</b>
          </button>
        </div>
        <div className="ppitches">
          {selectedDate === "WED 8 NOV" && (
            <>
              <div className="room">
                <p>
                  {" "}
                  <BiTimeFive />
                  <b>10:00 - 10:30</b>
                </p>
              </div>
              <table className="table table-bordered table-dark custom-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Jacob Demuynck</td>
                    <td>
                      IC-Errors: Interactive Identification and Correction of
                      Label Errors in High-Dimensional Data
                    </td>
                  </tr>
                  <tr>
                    <td>Lucas Lageweg</td>
                    <td>
                      Generative Expression Constrained Knowledge-based decoding
                      for Open data
                    </td>
                  </tr>
                  <tr>
                    <td>Anton Donle</td>
                    <td>
                      The performance of a robust Bayesian approach to the 20
                      questions game under different patterns of noise
                    </td>
                  </tr>
                  <tr>
                    <td>Rick van Kersbergen and Saskia Robben</td>
                    <td>
                      Towards Responsible AI Code Development: A Six-Value
                      Process Model for Junior and Novice AI Programmers
                    </td>
                  </tr>
                  <tr>
                    <td>Maris Galesloot</td>
                    <td>Online Planning in MPOMDPs with Many Agents</td>
                  </tr>
                  <tr>
                    <td>
                      Parsa Paiman, Nick van de Berg and Heleen van Beekhuizen
                    </td>
                    <td>
                      Advancing In-vivo Vulvar Tumour Detection: Combining AI
                      and Hyperspectral Imaging
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Muratzhan Kyranbay, Johan Rogiers, Roel Leus and Tim
                      Hellemans
                    </td>
                    <td>
                      Predicting the performance of an algorithm for the
                      Capacitated Vehicle Routing Problem with Time Windows
                    </td>
                  </tr>
                  <tr>
                    <td>Yde Jansen, Sjoerd Boersma and Natasha Alechina</td>
                    <td>
                      Optimal Control of Lettuce Greenhouse Horticulture using
                      Model-Free Reinforcement Learning
                    </td>
                  </tr>
                  <tr>
                    <td>Chiara Thöni</td>
                    <td>
                      The online optimization of brain-computer interface
                      stimulus parameters, a simulation
                    </td>
                  </tr>
                  
                  <tr>
                    <td>Filip Schlembach</td>
                    <td>
                      Conformal Multistep-Ahead Multivariate Time-Series
                      Forecasting
                    </td>
                  </tr>
                  <tr>
                    <td>Hidde Makimei, Shuai Wang and Willem Peursen</td>
                    <td>
                      Generating and Comparing AI-generated Biblical Images
                    </td>
                  </tr>
                  <tr>
                    <td>Maksim Terentev</td>
                    <td>Emergent Theory of Mind in Large Language Models</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {selectedDate === "THU 9 NOV" && (
            <>
              <div className="room">
                <p>
                  {" "}
                  <BiTimeFive />
                  <b>10:00 - 10:30</b>
                </p>
              </div>
              <table className="table table-bordered table-dark custom-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Saleh Alwer, Aske Plaat and Walter Kosters k</td>
                    <td>Graph Neural Networks for Chess</td>
                  </tr>
                  <tr>
                    <td>Bart L.T.J. Engelen and Wouter Duivesteijn</td>
                    <td>
                      Bottom-Up Search: A Distance-Based Search Strategy for
                      Supervised Local Pattern Mining on Multi-Dimensional
                      Target Spaces
                    </td>
                  </tr>
                  <tr>
                    <td>Robin De Haes</td>
                    <td>
                      A Benchmark for Recipe Understanding in Autonomous Agents
                    </td>
                  </tr>
                  <tr>
                    <td>Erik Slingerland and Marcio Fuckner</td>
                    <td>
                      From Novice to Composer: Utilizing Machine Learning to
                      Facilitate Music Creation with MIDI Generation and Sample
                      Extraction
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Emiel Witting, Yasin I. Tepeli and Joana P. Gonçalves
                    </td>
                    <td>
                      Deep Domain Adaptation without Access to a Specific Target
                      Domain
                    </td>
                  </tr>
                  <tr>
                    <td>Dieter Vandesande and Bart Bogaerts</td>
                    <td>Towards Certified MaxSAT Solving</td>
                  </tr>
                  <tr>
                    <td>Andrei Rykov and Sibylle Hess</td>
                    <td>Robust Deep Spectral Clustering</td>
                  </tr>
                  <tr>
                    <td>Alexander de Ranitz, Ardion Beldad and Elena Mocanu</td>
                    <td>
                      Enhancing Learning in Sparse Neural Networks: A Hebbian
                      Learning Approach
                    </td>
                  </tr>
                  <tr>
                    <td>Anisha Sachdeva and Beat Signer</td>
                    <td>
                      Inbox Harmony: A Recommendation System to Manage and
                      Organise Emails Based on PIM Principles
                    </td>
                  </tr>
                  <tr>
                    <td>Gabriel Hoogerwerf</td>
                    <td>
                      Mining meaning: A semantic-aware approach to sentence
                      embedding.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Jegor Zelenjak, Ioan-Cristian Oprea, Alexandru Dumitriu,
                      Sicco Verwer and Azqa Nadeem
                    </td>
                    <td>
                      Using Sink States to Learn from Infrequent Data in an
                      Automaton Model
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Inas Bosch, Tom Lenaerts, Barbara Gravel and Alexandre
                      Renaux
                    </td>
                    <td>
                      Knowledge graph embeddings for the prediction of
                      pathogenic gene pairs
                    </td>
                  </tr>
                  <tr>
                    <td>Gregory Go, Paula Roncaglia and Sharon Ong</td>
                    <td>
                      Genetic Classification of Accented Speech from Audio
                      Recordings of Spoken Nonsense Words
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {selectedDate === "FRI 10 NOV" && (
            <>
              <div className="room">
                <p>
                  {" "}
                  <BiTimeFive />
                  <b>10:00 - 10:30</b>
                </p>
              </div>
              <table className="table table-bordered table-dark custom-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      Igor Smit, Zaharah Bukhsh, Yingqian Zhang and Mykola
                      Pechenizkiy
                    </td>
                    <td>
                      Learning to Be Efficient and Fair for Collaborative Order
                      Picking
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Andrei C. Tociu, Yasin I. Tepeli and Joana P. Gonçalves
                    </td>
                    <td>
                      Importance Weighting Domain Adaptation Beyond Specific
                      Target Domains
                    </td>
                  </tr>
                  <tr>
                    <td>Niels Schelleman and Wouter Duivesteijn</td>
                    <td>
                      Exceptional In So Many Domains: Exploring Exceptional
                      Model Mining in Unstructured Data
                    </td>
                  </tr>
                  
                  <tr>
                    <td>Marko Petkovic and Vlado Menkovski</td>
                    <td>Generative AI descriptors for precursor microRNA</td>
                  </tr>
                  <tr>
                    <td>Merlijn Krale</td>
                    <td>Active Measuring in Uncertain Environments</td>
                  </tr>
                  <tr>
                    <td>Lisanne Henzen and Pascal Wiggers</td>
                    <td>
                      Reducing Bias in the ASR 'Whisper' Model: A Synthetic
                      Speech Data Approach
                    </td>
                  </tr>
                  <tr>
                    <td>Oscar Oosterling</td>
                    <td>
                      Mitigating Intersectional Bias in Toxic Comment
                      Classification Models through Data Balancing
                    </td>
                  </tr>
                  <tr>
                    <td>Coen Rouwmaat</td>
                    <td>
                      Detecting and Mitigating Goal Misgeneralisation with
                      Logical Interpretability Tools
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sam Vanspringel, Alexandra Cimpean, Pieter Libin and Ann
                      Nowé
                    </td>
                    <td>
                      A general purpose framework for fairness in job hiring
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Marloes Middelweerd, Wouter Merkx, Pedro Zattoni Scroccaro
                      and Bilge Atasoy
                    </td>
                    <td>
                      Learning Customer Behavior in Online Grocery Services
                    </td>
                  </tr>
                  <tr>
                    <td>Eline Bovy</td>
                    <td>
                      The Underlying Belief Model of Uncertain Partially
                      Observable Markov Decision Processes
                    </td>
                  </tr>
                  <tr>
                    <td>Kristian Gogora and Tom Viering</td>
                    <td>
                      Assessing Convexity Characteristics of Learning Curves
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </div>
  );
};

export default PosterPitches;
