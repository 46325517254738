/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Navigation.css";
import { CSSTransition } from "react-transition-group";
import hamb from "../../assets/hamburger.png";

export default function Navigation() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // eslint-disable-next-line
  const [isTransparent, setTransparent] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 920px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0) {
      setTransparent(true);
    } else {
      setTransparent(false);
    }
  };
  const location = useLocation();
  window.addEventListener("scroll", handleScroll);

  return (
    <header className="Header">
      <div>
        <a href="/">
          {" "}
          <img
            src={require("../../assets/BNAIC BeNeLearn 01-04.png")}
            className="Logo"
            alt="logo"
          />{" "}
        </a>
      </div>

      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={1}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav" style={{ display: "grid" }}>
          {location.pathname !== "/" && <a href="/">← Home</a>}
          
          {location.pathname !== "/registration" && (
            <a href="/registration">Registration</a>
          )}
          {location.pathname !== "/venue" && <a href="/venue">Venue</a>}
          {location.pathname !== "/attending" && (
            <a href="/attending">Attending</a>
          )}

          {location.pathname !== "/programme" && (
            <a href="/programme">Programme</a>
          )}
          {location.pathname !== "/organisation" && (
            <a href="/organisation">Organisation</a>
          )}
          {location.pathname !== "/callforpapers" && (
            <a href="/callforpapers">Call for Papers</a>
          )}
          
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className="Burger">
        <img src={hamb} alt="responsivemenu" />
      </button>
    </header>
  );
}
