import "./Organisation.css";
import React, { useState } from "react";
// importing the array of objects which contains data in data.js
import {
  gChair,
  leChair,
  loChair,
  pChair,
  pwChair,
} from "../Slider/BnaicOrganizers";
import "../Slider/Slider.css";
import Footer2 from "../FooterForOthers/Footer2";
import Navigation from "../Navigation/Navigation";
import Logo from "../Logos/Logo";

const Organisation = () => {
  // eslint-disable-next-line
  const [value, setValue] = useState(0);
  // eslint-disable-next-line
  const [value1, setValue1] = useState(0);
  // eslint-disable-next-line
  const [value2, setValue2] = useState(0);
  // eslint-disable-next-line
  const [value3, setValue3] = useState(0);
  // eslint-disable-next-line
  const [value4, setValue4] = useState(0);
  // eslint-disable-next-line
  const [value5, setValue5] = useState(0);
  // eslint-disable-next-line
  const [value6, setValue6] = useState(0);

  return (
    <div className="org">
      <div className="org-main">
        <Navigation />
        <div id="ourteam" className="teamsection">
          <div className="text-center mb-6 px-md-8">
            {" "}
            <span className="slide-header" style={{ color: "#00a6d6" }}>
              GENERAL CHAIRS
            </span>{" "}
          </div>

          <div className="container-slide">
            <div className="slide-all">
              <img src={gChair[value].image} alt={gChair[value].id} />
              <h2 style={{ color: "#00a6d6" }}>{gChair[value].name}</h2>
              <h3>{gChair[value].job}</h3>
            </div>
            <div className="slide-all">
              <img src={gChair[1].image} alt={gChair[1].id} />
              <h2 style={{ color: "#00a6d6" }}>{gChair[1].name}</h2>
              <h3>{gChair[1].job}</h3>
            </div>
          </div>
        </div>
        <div id="ourteam" className="teamsection">
          <div className="text-center mb-6 px-md-8">
            {" "}
            <span className="slide-header" style={{ color: "#00a6d6" }}>
              PROGRAMME CHAIRS
            </span>{" "}
          </div>

          <div className="container-slide">
            <div className="slide-all">
              <img src={pChair[1].image} alt={pChair[1].id} />
              <h2 style={{ color: "#00a6d6" }}>{pChair[1].name}</h2>
              <h3>{pChair[1].job}</h3>
            </div>
            <div className="slide-all">
              <img src={pChair[value2].image} alt={pChair[value5].id} />
              <h2 style={{ color: "#00a6d6" }}>{pChair[value5].name}</h2>
              <h3>{pChair[value5].job}</h3>
            </div>
            <div className="slide-all">
              <img src={pChair[2].image} alt={pChair[2].id} />
              <h2 style={{ color: "#00a6d6" }}>{pChair[2].name}</h2>
              <h3>{pChair[2].job}</h3>
            </div>
          </div>
        </div>
        <div id="ourteam" className="teamsection-one">
          <div className="text-center mb-6 px-md-8">
            {" "}
            <span className="slide-header" style={{ color: "#00a6d6" }}>
              PUBLICITY AND WEB CHAIR
            </span>{" "}
          </div>

          <div className="container-slide">
            <div className="slide-all">
              <img src={pwChair[value2].image} alt={pwChair[value2].id} />
              <h2 style={{ color: "#00a6d6" }}>{pwChair[value2].name}</h2>
              <h3>{pwChair[value2].job}</h3>
            </div>
          </div>
        </div>

        <div id="ourteam" className="teamsection">
          <div className="text-center mb-6 px-md-8">
            {" "}
            <span className="slide-header" style={{ color: "#00a6d6" }}>
              LOCAL ORGANISATION CHAIRS
            </span>{" "}
          </div>

          <div className="container-slide">
            <div className="slide-all">
              <img src={loChair[value3].image} alt={loChair[value3].id} />
              <h2 style={{ color: "#00a6d6" }}>{loChair[value3].name}</h2>
              <h3>{loChair[value3].job}</h3>
            </div>
            <div className="slide-all">
              <img src={loChair[1].image} alt={loChair[1].id} />
              <h2 style={{ color: "#00a6d6" }}>{loChair[1].name}</h2>
              <h3>{loChair[1].job}</h3>
            </div>
            <div className="slide-all">
              <img src={loChair[2].image} alt={loChair[2].id} />
              <h2 style={{ color: "#00a6d6" }}>{loChair[2].name}</h2>
              <h3>{loChair[2].job}</h3>
            </div>
          </div>
        </div>
        <div id="ourteam" className="teamsection">
          <div className="text-center mb-6 px-md-8">
            {" "}
            <span className="slide-header" style={{ color: "#00a6d6" }}>
              LOCAL EXPERTISE
            </span>{" "}
          </div>

          <div className="container-slide">
            <div className="slide-all">
              <img src={leChair[1].image} alt={leChair[1].id} />
              <h2 style={{ color: "#00a6d6" }}>{leChair[1].name}</h2>
              <h3>{leChair[1].job}</h3>
            </div>
            <div className="slide-all">
              <img src={leChair[value4].image} alt={leChair[value4].id} />
              <h2 style={{ color: "#00a6d6" }}>{leChair[value4].name}</h2>
              <h3>{leChair[value4].job}</h3>
            </div>
            <div className="slide-all">
              <img src={leChair[2].image} alt={leChair[2].id} />
              <h2 style={{ color: "#00a6d6" }}>{leChair[2].name}</h2>
              <h3>{leChair[2].job}</h3>
            </div>
          </div>
        </div>
        <div id="ourteam" className="teamsection2">
          <div className="text-center mb-6 px-md-8">
            {" "}
            <span className="slide-header" style={{ color: "#00a6d6" }}>
            Programme Chair Assistants
            </span>{" "}
          </div>

          <div className="container-slides">
            <p>Kerem Yoner, TU Delft</p>
            <p>Clinton Cao, Delft University of Technology</p>
          </div>
        </div>
        <div id="ourteam" className="teamsection2">
          <div className="text-center mb-6 px-md-8">
            {" "}
            <span className="slide-header" style={{ color: "#00a6d6" }}>
            Programme Committee members:  
            </span>{" "}
          </div>

          <div className="container-slides">
          <p>Wannes Meert, Katholieke Universiteit Leuven</p>
<p>Gerasimos Spanakis, Maastricht University</p>
<p>Luca Laurenti, TU Delft</p>
<p>Johan Kwisthout, Radboud University</p>
<p>Thiago D. Simão, Radboud University</p>
<p>Laurens Bliek, Eindhoven University of Technology</p>
<p>Floris Bex, Utrecht University</p>
<p>Nils Jansen, Radboud University</p>
<p>Daan Bloembergen, City of Amsterdam</p>
<p>Chris Develder, Ghent University</p>
<p>Paul Van Eecke, Vrije Universiteit Brussel</p>
<p>Bert Bredeweg, University of Amsterdam</p>
<p>Tony Belpaeme, Ghent University</p>
<p>Leon van der Torre, University of Luxembourg</p>
<p>Arjen Hommersom, Open University of the Netherlands</p>
<p>Amin Sharifi Kolarijani, Delft University of Technology</p>
<p>Peter van der Putten, LIACS, Leiden University & Pegasystems</p>
<p>Jan Lemeire, Vrije Universiteit Brussel</p>
<p>Christoph Schommer, University of Luxembourg</p>
<p>Raj Thilak Rajan, Delft university of technology</p>
<p>Mustafa Mert Çelikok, Aalto University</p>
<p>M. Birna van Riemsdijk, University of Twente</p>
<p>Tom Lenaerts, Universite Libre de Bruxelles</p>
<p>Raf Van de Plas, Delft University of Technology</p>
<p>Jan Ramon, INRIA</p>
<p>Yingqian Zhang, Eindhoven University of Technology</p>
<p>Marco Loog, Radboud University</p>
<p>Mohammad Khosravi, Delft University of Technology</p>
<p>Hendrik Blockeel, Katholieke Universiteit Leuven</p>
<p>Emir Demirović, Delft University of Technology</p>
<p>Egon L. van den Broek, Utrecht University</p>
<p>Tibor Bosse, Radboud University</p>
<p>Ad Feelders, Utrecht University</p>
<p>Vincent Francois, VU Amsterdam</p>
<p>Sebastijan Dumancic, Katholieke Universiteit Leuven</p>
<p>Dirk Thierens, Utrecht University</p>
<p>Walter Kosters, LIACS, Leiden University</p>
<p>Shenghui Wang, University of Twente</p>
<p>Stephan Sigg, Aalto University</p>
<p>Remco Veltkamp, Utrecht University</p>
<p>Mitra Baratchi, University of Twente</p>
<p>John Lee, Université catholique de Louvain</p>
<p>Matthijs T. J. Spaan, Delft University of Technology</p>
<p>Yuliya Shapovalova, Radboud University</p>
<p>Yvan Saeys, Ghent University</p>
<p>Pierre Geurts, University of Liège</p>
<p>Jens Kober, Delft University of Technology</p>
<p>Mathias Verbeke, KU Leuven</p>
<p>Tom Claassen, Radboud University</p>
<p>Jef Wijsen, University of Mons</p>
<p>Isel Grau, Eindhoven University of Technology</p>
<p>Stephan Bongers, Delft University of Technology</p>
<p>Zaharah A. Bukhsh, Eindhoven University of Technology</p>
<p>Floris Geerts, University of Antwerp</p>
<p>Peter Lucas, University of Twente</p>
<p>Jakub Tomczak, Vrije Universiteit Amsterdam</p>
<p>Suk-Chung Yoon, Widener University</p>
<p>Michel Klein, Vrije Universiteit Amsterdam</p>
<p>Len Feremans, Universiteit Antwerpen</p>
<p>Jilles Dibangoye, University of Groningen</p>
<p>John-Jules Meyer, Utrecht University</p>
<p>Tim van Erven, University of Amsterdam</p>
<p>Zhao Yang, Leiden University</p>
<p>Mark H. M. Winands, Maastricht University</p>
<p>Luis A. Leiva, University of Luxembourg</p>
<p>Nicolas Gillis, Université de Mons</p>
<p>Tom Heskes, Radboud University</p>
<p>Fatiha Saïs, Paris Saclay University</p>
<p>Walter Daelemans, University of Antwerp</p>
<p>Joost Vennekens, KU Leuven</p>
<p>Aske Plaat, Leiden University</p>
<p>Davide Grossi, University of Groningen</p>
<p>Hui Wang, Leiden University</p>
<p>Arnoud Visser, University of Amsterdam</p>
<p>Yaoxin Wu, Eindhoven University of Technology</p>
<p>Henry Prakken, University of Utrecht & University of Groningen</p>
<p>Menno van Zaanen, South African Centre for Digital Language Resources</p>
<p>Mark Hoogendoorn, Vrije Universiteit Amsterdam</p>
<p>Elvin Isufi, Delft University of Technology</p>
<p>Mehdi Dastani, Utrecht University</p>
<p>Bart Bogaerts, Vrije Universiteit Brussel (VUB)</p>
<p>Willem Waegeman, Ghent University</p>
<p>Marieke van Vugt, University of Groningen</p>
<p>Cédric Pruski, Luxembourg Institute of Science and Technology</p>
<p>Benoit Macq, UCL, Belgium</p>
<p>Yolanda Spinola-Elias, University of Seville</p>
<p>Sven Mayer, LMU Munich</p>

          </div>
        </div>
      </div>
      
      <div className="sponsor-org">
        <Logo />
      </div>
      <div>
        <Footer2 />
      </div>
    </div>
  );
};

export default Organisation;
