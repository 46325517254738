import React from "react";
import "../Footer/Footer.css"
import bnaiclogo from "../../assets/BNAIC BeNeLearn 01-04.png";
const Footer2 = () => {
  

  return (
    <div className="pg-footer">
      <footer className="footer">
        <svg
          className="footer-wave-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 100"
          preserveAspectRatio="none"
        >
          <path
            className="footer-wave-path"
            d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
          ></path>
        </svg>
        <div className="footer-content">
          <div className="footer-content-column">
            <div className="footer-logo">
              <a className="footer-logo-link" href="/">
                <img src={bnaiclogo} alt="bnaic" />
              </a>
             
            </div>
            <div className="footer-menu"></div>
          </div>
          <div className="footer-content-column">
            <div className="footer-menu">
              <h2 className="footer-menu-name"> BNAIC / BeNeLearn</h2>
              <ul id="menu-company" className="footer-menu-list">
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <a rel="noopener noreferrer" href="/">
                   Home
                  </a>
                </li>
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              
            </div>
          </div>
          <div className="footer-content-column">
            <div className="footer-menu">
              <h2 className="footer-menu-name"> Quick Links</h2>
              <ul id="menu-quick-links" className="footer-menu-list">
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <a rel="noopener noreferrer" href="/organisation">
                   Organisation
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <a rel="noopener noreferrer" href="/registration">
                    Registration
                  </a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/venue">Venue</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/callforpapers">Call for Papers</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/programme">Programme</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page">
                  <a href="/attending">Attending</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content-column">
          <div className="footer-call-to-action">
              <h2 className="footer-call-to-action-title">Email</h2>
              <p className="footer-call-to-action-link-wrapper">
                bnaic23-generalchairs@tudelft.nl
              </p>
              <p className="footer-call-to-action-link-wrapper">bnaic23-pcchairs@tudelft.nl</p>
              <p className="footer-call-to-action-link-wrapper"> bnaic23-localchairs@tudelft.nl</p>
            </div>
          </div>
          <div className="footer-social-links"></div>
        </div>
        <div className="footer-copyright">
          <div className="footer-copyright-wrapper">
            <p className="footer-copyright-text">
            <p>
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    marginRight: "2px",
                    fontWeight: "bold",
                  }}
                  href="https://ehaportfolio.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Webmaster: EHA
                </a>
                &copy; {new Date().getFullYear()} TU Delft. All Rights Reserved.{" "}
              </p>
            </p>
          </div>
        </div>
        
      </footer>
    </div>
  );
};

export default Footer2;
