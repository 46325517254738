import React from "react";
import "../Registration/Registration.css";
import "./FullProgram.css";
import { useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import Navigation2 from "../Navigation2/Navigation2";
import Footer2 from "../FooterForOthers/Footer2";

const FullProgram = () => {
  const [selectedTime, setselectedTime] = useState("9:00 - 10:30");

  const handleDateClick = (date) => {
    setselectedTime(date);
  };

  

  const wedRoomA = [
    {
      name: "Taking a Shortcut Through Phase Space: A Multi-task Learning Approach to Solving Differential Equations",
      task: "Floriano Tori and Vincent Ginis",
    },
    {
      name: "Accurate online training of dynamical spiking neural networks through Forward Propagation Through Time",
      task: "Bojian Yin, Federico Corradi and Sander Bohte",
    },
    {
      name: "Three types of incremental learning",
      task: "Gido van de Ven, Tinne Tuytelaars and Andreas Tolias",
    },
    {
      name: "Maximum Entropy Logistic Regression for Demographic Parity in Supervised Classification",
      task: "Flore Vancompernolle Vromman, Sylvain Courtain, Pierre Leleux, Constantin de Schaetzen, Eve Beghein, Alexia Kneip and Marco Saerens",
    },
  ];

  const wedRoomC = [
    {
      name: "Benchmarking Surrogate-based Optimisation Algorithms on Expensive Black-box Functions",
      task: "Laurens Bliek, Arthur Guijt, Rickard Karlsson, Sicco Verwer and Mathijs de Weerdt",
    },
    {
      name: "Robust Abstraction-Based Planning for Stochastic Dynamical Models with Epistemic Uncertainty",
      task: "Thom Badings, Licio Romao, Alessandro Abate and Nils Jansen",
    },
    {
      name: "Assessing the Optimality of Decentralized Inspection and Maintenance Policies for Stochastically Degrading Engineering Systems",
      task: "Prateek Bhustali and Charalampos P. Andriotis",
    },
    {
      name: "Synchromodal freight transport re-planning under service time uncertainty: An online model-assisted reinforcement learning",
      task: "Yimeng Zhang, Rudy R. Negenborn and Bilge Atasoy",
    },
  ];

  const wedRoomE = [
    {
      name: "Proportional Search Space Reduction: A Novel Metric for Cross-View Image Geo-Location",
      task: "Leon Debnath, Alexia Briassouli and Mirela Popa",
    },
    {
      name: "Abstract: Color-Dependent Prediction Stability of Popular CNN Image Classification Architectures",
      task: "Laurent Mertens, Elahe Yargholi, Jan Van den Stock, Hans Op de Beeck and Joost Vennekens",
    },
    {
      name: "Assessing aggressive driving behavior using attention-based models",
      task: "Jonathan Aechtner, Mirela Popa and Anna Wilbik",
    },
    {
      name: "Image Processing Perspectives of X-Ray Fluorescence Data in Cultural Heritage Sciences",
      task: "Henry Chopp, Alicia McGeachy, Matthias Alfeld, Oliver Cossairt, Marc Walton and Aggelos Katsaggelos",
    },
  ];

  const wedRoomF = [
    {
      name: "Faster and more accurate pathogenic combination predictions with VarCoPP2.0",
      task: "Nassim Versbraegen, Barbara Gravel, Charlotte Nachtegael, Alexandre Renaux, Emma Verkinderen, Ann Nowé, Tom Lenaerts and Sofia Papadimitriou",
    },
    {
      name: "A Binning Approach for Predicting Long-Term Prognosis in Multiple Sclerosis",
      task: "Robbe D'Hondt, Sinéad Moylett, An Goris and Celine Vens",
    },
    {
      name: "Deep Learning Classifiers to Reduce False Positives in Bone Lesion Segmentation from CT Scans of Multiple Myeloma",
      task: "Munirdin Jadikar, Martijn van Leeuwen, Thijs van Oudheusden, Sebastian Oei, Rik Kint, Bastiaan Steunenberg, Erik Ranschaert, Gerlof Bosma, Gorkem Saygili and Sharon Ong",
    },
    {
      name: "The pitfalls of negative data bias for the T-cell epitope specificity challenge",
      task: "Ceder Dens, Kris Laukens, Wout Bittremieux and Pieter Meysman",
    },
  ];

  const thuRoomA = [
    {
      name: "BNVKI - Polish AI Society: Classifier selection for imbalanced data stream classification",
      task: "Paweł Zyblewski",
    },
    {
      name: "BNVKI - Polish AI Society: Axiomatization of the Walk-Based Centrality Measures",
      task: "Tomasz Wąs",
    },
  ];

  const thuRoomC = [
    {
      name: "Safe Policy Improvement in POMDPs",
      task: "Marnix Suilen, Thiago D. Simão and Nils Jansen",
    },
    {
      name: "Training and Transferring Safe Policies in Reinforcement Learning",
      task: "Qisong Yang, Thiago D. Simão, Nils Jansen, Simon Tindemans and Matthijs T. J. Spaan",
    },
    {
      name: "Recursive Small-Step Multi-Agent A*: A Faster Exact Solver for Dec-POMDPs",
      task: "Wietze Koops, Nils Jansen, Sebastian Junges and Thiago Simão",
    },
  ];

  const thuRoomE = [
    {
      name: "Human Recognition of Emotions Expressed by Human-like Avatars on 2D Screens",
      task: "Erik van Haeringen, Marco Otte and Charlotte Gerritsen",
    },
    {
      name: "Surrealistic-like Image Generation with Vision-Language Models",
      task: "Elif Ayten, Shuai Wang and Hjalmar Snoep",
    },
    {
      name: "Transfer Learning between Motor Imagery Datasets using Deep Learning - Validation of Framework and Comparison of Datasets",
      task: "Pierre Guetschel and Michael Tangermann",
    },
  ];

  const thuRoomF = [
    {
      name: "Effectiveness of User States, Demographics and Traits in Persuading to Quit Smoking",
      task: "Nele Albers, Mark A. Neerincx and Willem-Paul Brinkman",
    },
    {
      name: "Determinants of physical activity behavior change in (online) interventions, and gender-specific differences: a Bayesian network model",
      task: "Simone Tummers, Arjen Hommersom, Lilian Lechner, Roger Bemelmans and Catherine Bolman",
    },
    {
      name: "Predicting Depression and Anxiety Risk in Dutch Neighborhoods from Street-View Images",
      task: "Nin Khodorivsko and Giacomo Spigler",
    },
  ];

  const thuRoomElevenA = [
    {
      name: "No Imputation without Representation",
      task: "Oliver Urs Lenz, Daniel Peralta and Chris Cornelis",
    },
    {
      name: "Automatic Tagging Suggestion for Database Enrichment",
      task: "Emanuele Cuono Amoruso, Sietske Tacoma and Huib Aldewereld",
    },
    {
      name: "Investigating Imputation Methods for Handling Missing Data",
      task: "Jelle Maas, Job G.W.T. Römer, Işıl Baysal Erez and Maurice van Keulen",
    },
  ];
  const thuRoomElevenC = [
    {
      name: "Expertise Trees Resolve Knowledge Limitations in Collective Decision-Making",
      task: "Axel Abels, Tom Lenaerts, Vito Trianni and Ann Nowé",
    },
    {
      name: "Fair and Optimal Decision Trees: A Dynamic Programming Approach",
      task: "Emir Demirović, Koos Van Der Linden and Mathijs De Weerdt",
    },
    {
      name: "Optimal Decision Tree Policies for Markov Decision Processes",
      task: "Daniël Vos and Sicco Verwer",
    },
  ];
  const thuRoomElevenE = [
    {
      name: "Machine learning challenges in diagnosing lithography machines: high dimensionality, complexity and interpretability",
      task: "Carlo Lancia (ASML)",
    },
    {
      name: "Machine learning @ ORTEC: learning to optimize and learning what to optimize",
      task: "Wouter Kool (ORTEC)",
    },
    {
      name: "Supporting non-expert users in modelling and understanding combinatorial optimisation / operation research.",
      task: "Kylian Kropf (Dyflexis)",
    },
  ];
  const thuRoomElevenF = [
    {
      name: "Learning to walk with a soft actor-critic approach",
      task: "Gijs de Jong, Leon Eshuijs and Arnoud Visser",
    },
    {
      name: "Contextual Qualitative Deterministic Models for Self-Learning Embodied Agents",
      task: "Marco Van Cleemput and Jan Lemeire",
    },
    {
      name: "An extended abstract on the journal article: 'Search-based task and motion planning for hybrid systems: Agile autonomous vehicles'",
      task: "Zlatan Ajanovic, Enrico Regolin, Barys Shyrokau, Hana Ćatić, Martin Horn and Antonella Ferrara",
    },
  ];

  const thuRoomThirteenA = [
    {
      name: "Graph-Time Convolutional Neural Networks: Architecture and Theoretical Analysis",
      task: "Mohammad Sabbaqi and Elvin Isufi",
    },
    {
      name: "Convolutional Filtering and Learning on Simplicial Complexes",
      task: "Maosheng Yang and Elvin Isufi",
    },
    {
      name: "Beyond Flattening: Hilbert Space-filling Curves for Enhanced Locality Preservation in CNNs",
      task: "Bert Verbruggen and Vincent Ginis",
    },
    {
      name: "Linear CNNs Discover the Statistical Structure of the Dataset Using Only the Most Dominant Frequencies",
      task: "Hannah Pinson, Joeri Lenaerts and Vincent Ginis",
    },
  ];
  const thuRoomThirteenC = [
    {
      name: "Incremental Profit per Conversion: a Response Transformation for Uplift Modeling in E-Commerce Promotions",
      task: "Hugo Manuel Proença and Felipe Moraes",
    },
    {
      name: "Causal Entropy and Information Gain for Measuring Causal Control",
      task: "Francisco N. F. Q. Simoes, Mehdi Dastani and Thijs van Ommen",
    },
    {
      name: "A Comparative Study of Adaptive MCMC Schemes with Stopped and Diminishing Adaptation",
      task: "Bowen Liu, Edna Milgo, Nixon Ronoh and Bernard Manderick",
    },
    {
      name: "Encore Abstract: Presumably Correct Decision Sets",
      task: "Gonzalo Nápoles, Isel Grau, Agnieszka Jastrzebska and Yamisleydi Salgueiro",
    },
  ];
  const thuRoomThirteenE = [
    {
      name: "Fully Autonomous Programming with Large Language Models",
      task: "Vadim Liventsev, Anastasiia Grishina, Aki Härmä and Leon Moonen",
    },
    {
      name: "Improving the Dark Web Classifier with Active Learning and Annotation Error Detection",
      task: "Pablo San Gil, Romana Pernisch, Eljo Haspels and Mark van Staalduinen",
    },
    {
      name: "Tree Variational Autoencoder for Code",
      task: "Vadim Liventsev, Sander de Bruin, Aki Härmä and Milan Petković",
    },
    {
      name: "Exploring Knowledge Extraction Techniques for System Dynamics Modelling: Comparative Analysis and Considerations",
      task: "Roos Bakker, Gino Kalkman, Ioannis Tolios, Dominique Blok, Guido Veldhuis, Stephan Raaijmakers and Maaike de Boer",
    },
  ];
  const thuRoomThirteenF = [
    {
      name: "Trustworthy Artificial Intelligence in Medical Applications: A Mini Survey",
      task: "Mohsen Abbaspour Onari, Isel Grau, Marco S. Nobile and Yingqian Zhang",
    },
    {
      name: "Fostering Appropriate Trust in AI Agents through Integrity-Centered Explanations",
      task: "Siddharth Mehrotra, Carolina Centeio Jorge, Catholijn M. Jonker and Myrthe L. Tielman",
    },
    {
      name: "Tomea: an Explainable Method for Comparing Morality Classifiers across Domains",
      task: "Enrico Liscio, Oscar Araque, Lorenzo Gatti, Ionut Constantinescu, Catholijn M. Jonker, Kyriaki Kalimeri and Pradeep K. Murukannaiah",
    },
    {
      name: "Reflection Machines: Introducing ‘Trias Reflecta’ to increase meaningful human control",
      task: "Chantal Banga, Marije Bekema, Kim Bladder, Loes Gennissen, Lotte de Groot and Pim Haselager",
    },
  ];

  const thuRoomFifteenA = [
    {
      name: "Establishing Shared Query Understanding in an Open Multi-Agent System Extended Abstract",
      task: "Nikolaos Kondylidis, Ilaria Tiddi and Annette Ten Teije",
    },
    {
      name: "Neural Probabilistic Logic Programming in Discrete-Continuous Domains",
      task: "Lennert De Smet, Pedro Zuidberg Dos Martires, Robin Manhaeve, Giuseppe Marra, Angelika Kimmig and Luc De Raedt",
    },
    {
      name: "Rights and Practical Reasoning in Deontic Logic",
      task: "Huimin Dong, Dragan Doder, Xu Li, Réka Markovich, Leon van der Torre and Marc van Zee",
    },
    {
      name: "Reasoning About Group Responsibility for Taking Risks",
      task: "Maksim Gladyshev, Natasha Alechina, Mehdi Dastani and Dragan Doder",
    },
  ];
  const thuRoomFifteenC = [
    {
      name: "Multi-Agent Communication using Difference Rewards Policy Gradients",
      task: "Simon Vanneste, Astrid Vanneste, Tom De Schepper, Siegffried Mercelis, Peter Hellinckx and Kevin Mets",
    },
    {
      name: "Laser Learning Environment: A new environment for coordination-critical multi-agent tasks",
      task: "Yannick Molinghen, Raphaël Avalos, Mark Van Achter, Ann Nowé and Tom Lenaerts",
    },
    {
      name: "Language Games Meet Multi-Agent Reinforcement Learning: A Case Study for the Naming Game",
      task: "Paul Van Eecke, Katrien Beuls, Jérôme Botoko Ekila and Roxana Rădulescu",
    },
    {
      name: "Continuous Communication with Factorized Policy Gradients in Multi-agent Deep Reinforcement Learning",
      task: "Changxi Zhu, Mehdi Dastani and Shihan Wang",
    },
  ];
  const thuRoomFifteenE = [
    {
      name: "Neuro-Symbolic Procedural Semantics for Reasoning-Intensive Visual Dialogue Tasks",
      task: "Lara Verheyen, Jérôme Botoko Ekila, Jens Nevens, Paul Van Eecke and Katrien Beuls",
    },
    {
      name: "Minimalist Entity Disambiguation for Mid-Resource Languages",
      task: "Benno Kruit",
    },
    {
      name: "Enhancing Fake News Classification Through an Argumentation-Based Pipeline",
      task: "Mayla Kersten and Giovanni Sileno",
    },
    {
      name: "The Role of Personal Perspectives in Open-Domain Dialogue: Towards Enhanced Data Modelling and Long-term Memory",
      task: "Lea Krause, Selene Baez Santamaria, Lucia Donatelli and Piek Vossen",
    },
  ];
  const thuRoomFifteenF = [
    {
      name: "Why do penguins fly? Explaining Boolean Classifiers with Non-monotonic Background Theories",
      task: "Tjitze Rienstra",
    },
    {
      name: "BELLATREX: Building Explanations through a LocaLly AccuraTe Rule EXtractor",
      task: "Klest Dedja, Felipe Kenji Nakano, Konstantinos Pliakos and Celine Vens",
    },
    {
      name: "Motivating explanations in Bayesian networks using MAP-independence",
      task: "Johan Kwisthout",
    },
    {
      name: "Defining the Optimal Degree of Abstraction in Explanations with Kolmogorov complexity",
      task: "Jan Lemeire and Stefan Buijsman",
    },
  ];

  const friRoomA = [
    {
      name: "Vectorizing Timestamps by means of a Variational Autoencoder",
      task: "Gideon Rouwendaal and Xander Wilcke",
    },
    {
      name: "Direct Exoplanet Detection Using L1 Norm Low-Rank Approximation",
      task: "Hazan Daglayan, Simon Vary, Valentin Leplat, Nicolas Gillis and Pierre Antoine Absil",
    },
    {
      name: "GroupEnc: encoder with group loss for global structure preservation",
      task: "David Novak, Sofie Van Gassen and Yvan Saeys",
    },
  ];
  const friRoomC = [
    {
      name: "Forecasting Train Arrival Delays on the Polatlı – Eskişehir High-Speed Line in Turkey",
      task: "Özgül Ardıç",
    },
    {
      name: "Learning to Adapt Genetic Algorithms for Multi-Objective Flexible Job Shop Scheduling Problems",
      task: "Robbert Reijnen, Zaharah Bukhsh, Yingqian Zhang and Mateusz Guzek",
    },
    {
      name: "Machine Learning Applications in Route Planning for Attended Home Delivery",
      task: "Thomas R. Visser, Ruggiero Seccia, Wouter Merkx, Wouter Kool and Leendert Kok",
    },
    {
      name: "The EURO Meets NeurIPS 2022 Vehicle Routing Competition",
      task: "Wouter Kool, Laurens Bliek, Danilo Numeroso, Yingqian Zhang, Tom Catshoek, Kevin Tierney, Thibaut Vidal and Joaquim Gromicho",
    },
  ];
  const friRoomE = [
    {
      name: "Forecast reconciliation for vaccine supply chain optimization",
      task: "Bhanu Angam, Alessandro Beretta, Eli De Poorter, Matthieu Duvinage and Daniel Peralta",
    },
    {
      name: "Improving performance of heart rate time series classification by grouping subjects",
      task: "Michael Beekhuizen, Arman Naseri, David Tax, Ivo van der Bilt and Marcel Reinders",
    },
    {
      name: "Predicting Station-Level Bike Demand in Bike-Sharing Systems Using a Hybrid Neural Network",
      task: "Carlijn Jurriaans, Stijn Rotman and Eva Vanmassenhove",
    },
    {
      name: "Model-based Clustering of Individuals' Ecological Momentary Assessment Data for Improving Forecasting Performance",
      task: "Mandani Ntekouli, Gerasimos Spanakis, Lourens Waldorp and Anne Roefs",
    },
  ];
  const friRoomF = [
    {
      name: "Retrieval-based Question Answering with Passage Expansion using a Knowledge Graph",
      task: "Yiming Xu, Jan-Christoph Kalo and Benno Kruit",
    },
    {
      name: "Relevance feedback strategies for recall-oriented neural information retrieval",
      task: "Timo Kats, Peter van der Putten and Jan Scholtes",
    },
    {
      name: "The Effectiveness of Different Group Recommendation Strategies for Different Group Compositions",
      task: "Francesco Barile, Tim Draws, Oana Inel, Alisa Rieger, Amir Ebrahimi Fard, Rishav Hada and Nava Tintarev",
    },
    {
      name: "Potential Factors Leading to Popularity Unfairness in Recommender Systems: A User-Centered Analysis",
      task: "Masoud Mansoury, Finn Duijvestijn and Imane Mourabet",
    },
  ];

  return (
    <div className="registration">
      <Navigation2 />
      <div className="fullprogram">
        <div className="full-heading">
          <div className="fullProgram-heading">
            <h2>Papers to sessions</h2>
            <div className="pr-buttons">
              <button
                className="btn btn-outline-info"
                onClick={() => handleDateClick("13:00")}
              >
                <b>WED 8 NOV</b>
              </button>
              <button
                className="btn btn-outline-info"
                onClick={() => handleDateClick("9:00 - 10:00")}
              >
                <b>THU 9 NOV</b>
              </button>

              <button
                className="btn btn-outline-info"
                onClick={() => handleDateClick("9:00 - 10:30")}
              >
                <b>FRI 10 NOV</b>
              </button>
            </div>
            <div className="allTables">
              {selectedTime === "13:00" && (
                <>
                  <div  className="room">
                    <p id='talks1'>
                      <b >Contributed talks #1</b>
                    </p>
                    <p>
                      <b>Session : Machine Learning 1</b>
                    </p>
                    <p>
                      <b>Chair: Lynn Houthuys </b>
                    </p>
                    <p>
                      <b>Room A</b>
                    </p>
                    <p>
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                  </div>

                  {wedRoomA.length > 0 && (
                    <table className="table table-bordered table-dark custom-table ">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Authors</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wedRoomA.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.task}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <div className="room">
                    <p>
                      <b>Session : Machine Learning and Optimization</b>
                    </p>
                    <p>
                      <b>Chair: Wouter Kool</b>
                    </p>
                    <p>
                      <b>Room C</b>
                    </p>
                    <p>
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                  </div>

                  {wedRoomC.length > 0 && (
                    <table className="table table-bordered table-dark custom-table ">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Authors</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wedRoomC.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.task}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <div className="room">
                    <p>
                      <b>Session : Computer Vision 1</b>
                    </p>
                    <p>
                      <b>Chair: Arnoud Visser</b>
                    </p>
                    <p>
                      <b>Room E</b>
                    </p>
                    <p>
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                  </div>

                  {wedRoomE.length > 0 && (
                    <table className="table table-bordered table-dark custom-table ">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Authors</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wedRoomE.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.task}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <div className="room">
                    <p>
                      <b>Session : AI in Medicine</b>
                    </p>
                    <p>
                      <b>Chair: Bilge Atasoy</b>
                    </p>
                    <p>
                      <b>Room F</b>
                    </p>
                    <p>
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                  </div>

                  {wedRoomF.length > 0 && (
                    <table className="table table-bordered table-dark custom-table ">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Authors</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wedRoomF.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.task}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </>
              )}

              {/* __________________________________________________________________________________________________________________THU  */}
              {selectedTime === "9:00 - 10:00" && (
                <>
                  <div  className="room">
                    <div id='talks2'>
                    <p >
                      <b>Contributed talks #2</b>
                    </p>
                    </div>
                    

                    <p>
                      <b>Session : BNVKI - polish AI Society</b>
                    </p>
                    <p>
                      <b>Chair: Tom Lenaerts</b>
                    </p>
                    <p>
                      <b>Room A</b>
                    </p>
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>9:00 - 10:00</b>
                    </p>
                  </div>
                  {selectedTime === "9:00 - 10:00" && (
                    <>
                      {thuRoomA.length > 0 && (
                        <table className="table table-bordered table-dark custom-table">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Authors</th>
                            </tr>
                          </thead>
                          <tbody>
                            {thuRoomA.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.task}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      <div className="room">
                        <p>
                          <b>Session : Reinforcement Learning 1</b>
                        </p>
                        <p>
                          <b>Chair: Pieter Libin</b>
                        </p>
                        <p>
                          <b>Room C</b>
                        </p>
                        <p>
                          {" "}
                          <BiTimeFive />
                          <b>9:00 - 10:00</b>
                        </p>
                      </div>
                      {thuRoomC.length > 0 && (
                        <table className="table table-bordered table-dark custom-table">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Authors</th>
                            </tr>
                          </thead>
                          <tbody>
                            {thuRoomC.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.task}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      <div className="room">
                        <p>
                          <b>Session : Computer Vision 2</b>
                        </p>
                        <p>
                          <b>Chair: Michael Weinmann</b>
                        </p>
                        <p>
                          <b>Room E</b>
                        </p>
                        <p>
                          {" "}
                          <BiTimeFive />
                          <b>9:00 - 10:00</b>
                        </p>
                      </div>
                      {thuRoomE.length > 0 && (
                        <table className="table table-bordered table-dark custom-table">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Authors</th>
                            </tr>
                          </thead>
                          <tbody>
                            {thuRoomE.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.task}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}

                      <div className="room">
                        <p>
                          <b>Session : Human-Centric AI</b>
                        </p>
                        <p>
                          <b>Chair: Maaike de Boer</b>
                        </p>
                        <p>
                          <b>Room F</b>
                        </p>
                        <p>
                          {" "}
                          <BiTimeFive />
                          <b>9:00 - 10:00</b>
                        </p>
                      </div>
                      {thuRoomF.length > 0 && (
                        <table className="table table-bordered table-dark custom-table">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Authors</th>
                            </tr>
                          </thead>
                          <tbody>
                            {thuRoomF.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.task}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </>
                  )}

                  {/* __________________________________________________________________________________________________________________THU  */}
                  <div className="room">
                    <p id='talks3'>
                      <b>Contributed talks #3</b>
                    </p>
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>11:00 - 12:00</b>
                    </p>
                    <p>
                      <b>Session : Data Augmentation </b>
                    </p>
                    <p>
                      <b>Chair: Aske Plaat</b>
                    </p>
                    <p>
                      <b>Room A</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomElevenA.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>11:00 - 12:00</b>
                    </p>
                    <p>
                      <b>Session : Trees</b>
                    </p>
                    <p>
                      <b>Chair: Anna Lukina</b>
                    </p>
                    <p>
                      <b>Room C</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomElevenC.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>11:00 - 12:00</b>
                    </p>
                    <p>
                      <b>Session : Industry Talks</b>
                    </p>
                    <p>
                      <b>Chair: Bilge Atasoy</b>
                    </p>
                    <p>
                      <b>Room Room E</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomElevenE.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>11:00 - 12:00</b>
                    </p>
                    <p>
                      <b>Session : Reinforcement Learning 2</b>
                    </p>
                    <p>
                      <b>Chair: Matthijs Spaan</b>
                    </p>
                    <p>
                      <b>Room F </b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomElevenF.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* __________________________________________________________________________________________________________________THU  */}
                  <div className="room">
                    <p id='talks4'>
                      <b>Contributed talks #4</b>
                    </p>
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                    <p>
                      <b>Session : Machine Learning 2</b>
                    </p>
                    <p>
                      <b>Chair: Mert Çelikok</b>
                    </p>
                    <p>
                      <b>Room A</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomThirteenA.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                    <p>
                      <b>Session : Causality and Sampling</b>
                    </p>
                    <p>
                      <b>Chair: Jan Lemeire</b>
                    </p>
                    <p>
                      <b>Room C</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomThirteenC.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                    <p>
                      <b>Session : Natural Language Processing 1</b>
                    </p>
                    <p>
                      <b>Chair: Ayoub Bagheri</b>
                    </p>
                    <p>
                      <b>Room E</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomThirteenE.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>13:00 - 14:30</b>
                    </p>
                    <p>
                      <b>Session : Trustworthy AI</b>
                    </p>
                    <p>
                      <b>Chair: Johan Kwisthout</b>
                    </p>
                    <p>
                      <b>Room F</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomThirteenF.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* __________________________________________________________________________________________________________________THU  */}
                  <div className="room">
                    <p id='talks5'>
                      <b>Contributed talks #5</b>
                    </p>
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>15:30 - 17:00</b>
                    </p>
                    <p>
                      <b>Session : Logic and Reasoning</b>
                    </p>
                    <p>
                      <b>Chair: Zlatan Ajanovic</b>
                    </p>
                    <p>
                      <b>Room A</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomFifteenA.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>15:30 - 17:00</b>
                    </p>
                    <p>
                      <b>Session : Reinforcement Learning 3</b>
                    </p>
                    <p>
                      <b>Chair: Thomas Moerland</b>
                    </p>
                    <p>
                      <b>Room C</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomFifteenC.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>15:30 - 17:00</b>
                    </p>
                    <p>
                      <b>Session : Natural Language Processing 2</b>
                    </p>
                    <p>
                      <b>Chair: Jerry Spanakis</b>
                    </p>
                    <p>
                      <b>Room E </b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomFifteenE.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      {" "}
                      <BiTimeFive />
                      <b>15:30 - 17:00</b>
                    </p>
                    <p>
                      <b>Session : Explainable AI</b>
                    </p>
                    <p>
                      <b>Chair: Hannah Pinson</b>
                    </p>
                    <p>
                      <b>Room F</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {thuRoomFifteenF.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {/* __________________________________________________________________________________________________________________FRIDAY  */}
              {selectedTime === "9:00 - 10:30" && (
                <>
                  <div className="room">
                    <p id='talks6'>
                      {" "}
                      <b>Contributed talks #6</b>
                    </p>

                    <p>
                      <b>Session : Dimensionality and Multi-Modal Learning</b>
                    </p>
                    <p>
                      <b>Chair: Arnoud Visser</b>
                    </p>

                    <p>
                      <b>Room A</b>
                    </p>

                    <p>
                      {" "}
                      <BiTimeFive />
                      <b> 9:00 - 10:30</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {friRoomA.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      <b>Session : Machine Learning and Optimization 2</b>
                    </p>
                    <p>
                      <b>Chair: Laurens Bliek</b>
                    </p>

                    <p>
                      <b>Room C</b>
                    </p>

                    <p>
                      {" "}
                      <BiTimeFive />
                      <b> 9:00 - 10:30</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {friRoomC.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      <b>Session : Time Series</b>
                    </p>
                    <p>
                      <b>Chair: Mert Çelikok</b>
                    </p>

                    <p>
                      <b>Room E </b>
                    </p>

                    <p>
                      {" "}
                      <BiTimeFive />
                      <b> 9:00 - 10:30</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {friRoomE.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="room">
                    <p>
                      <b>Session : Information Retrieval and Recommendation</b>
                    </p>
                    <p>
                      <b>Chair: Stephan Bongers</b>
                    </p>

                    <p>
                      <b>Room F</b>
                    </p>

                    <p>
                      {" "}
                      <BiTimeFive />
                      <b> 9:00 - 10:30</b>
                    </p>
                  </div>
                  <table className="table table-bordered table-dark custom-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {friRoomF.map((item, index) => (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.task}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </div>
  );
};

export default FullProgram;
