import React from "react";
import Navigation from "../Navigation/Navigation";
import "../Registration/Registration.css";
import "./Attending.css";
import Footer2 from "../FooterForOthers/Footer2";
import momo from "../../assets/momo.png";
const Attending = () => {
  return (
    <div className="registration">
      <Navigation />
      <div className="att-heading">
        <p>
          Discover the perfect stay with our exclusive accommodation partnership
          with Time to Momo! Enjoy special discounts when you book your hotel
          through us. Explore your options and secure your ideal accommodation
          here.
        </p>
        <a
          href="https://www.timetomomo.com/en/visit/bnaic2023/?utm_medium=email&utm_source=organiser&utm_campaign=bnaic2023"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="momo" src={momo} />
        </a>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </div>
  );
};

export default Attending;
