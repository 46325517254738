import React from "react";
import "./Registration.css";
import Navigation from "../Navigation/Navigation";
import Footer2 from "../FooterForOthers/Footer2";
const Registration = () => {
  return (
    <div className="registration">
      <Navigation />
      <div className="registration-heading">
        <p style={{ textAlign: "left" }}>
          BNAIC/BeNeLearn 2023 is an in-person event and registration is
          mandatory for participation. Even though the conference is targetted
          at the Belgian and Dutch AI and machine learning community, we welcome
          participants from all over the world to participate.
        </p>

        <p>
          To register, please follow this link:{" "}
          <a
            href="https://www.aanmelder.nl/148997/subscribe"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            BNAIC/BeNeLearn Registration
          </a>
        </p>
        <div>
          <h1>Pricing</h1>
          <table className="table table-sm table-dark">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">
                  Early registration <br /> (by 20 October)
                </th>
                <th scope="col">
                  Late registration <br /> (by 7 November)
                </th>
                <th scope="col">
                  Onsite registration <br /> (from 8 November)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Regular</th>
                <td>€ 270</td>
                <td>€ 320</td>
                <td>€ 350</td>
              </tr>
              <tr>
                <th scope="row">PhD student</th>
                <td>€ 170</td>
                <td>€ 210</td>
              </tr>
              <tr>
                <th scope="row">MSc/BSc student</th>
                <td>€ 70</td>
                <td>€ 100</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>*Student registration requires proof of status.</p>
        <p>
        *<b>BSc/MSc student</b> presenting your thesis work as a type{" "}
          <b>D paper</b>? <b>BNVKI will reimburse your registration fee!</b>{" "}
          Please register as usual for the conference, and you will be informed
          late of the reimbursement procedure. Please note this applies to
          (former) <b>BSc/MSc students only</b>, not to PhD students presenting
          their PhD thesis work. It also applies only to type <b>D papers</b>.
        </p>
      </div>
      <div className="footer-m">
        <Footer2 />
      </div>
    </div>
  );
};

export default Registration;
