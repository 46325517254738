import React, { useEffect } from "react";
import "./Home.css";
import "aos/dist/aos.css";
import Aos from "aos";
import Footer from "../Footer/Footer";
import Logo from "../Logos/Logo";
import { BsCalendar3Week } from "react-icons/bs";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const textStyle = {
    textDecoration: 'line-through'
  };

  return (
    <div>
      <div className="home-main">
        <div className="grids">
          <div data-aos="fade-up" className="boxes-heading" id="section-1">
            <h2>What is BNAIC/BeNeLearn </h2>
          </div>
          <div data-aos="fade-up" className="boxes">
            <h4>
              BNAIC/BeNeLearn is the reference AI & ML conference for Belgium,
              Netherlands & Luxembourg. The combined conference will take place
              from November 8th till November 10th in Delft, The Netherlands and
              is organized by the Delft University of Technology, under the
              auspices of the{" "}
              <a href="http://www.bnvki.org/" target="_blank" rel="noreferrer">
                {" "}
                Benelux Association for Artificial Intelligence (BNVKI){" "}
              </a>
              and the{" "}
              <a href="https://siks.nl/">
                {" "}
                Netherlands Research School for Information and Knowledge
                Systems (SIKS)
              </a>
              .
            </h4>
          </div>
          <div data-aos="fade-up" className="boxes2">
            
            <h3 className="text-box-top">Important dates</h3>
            <ul>
              <li>
                <h3 className="text-box-lower">
                  All deadlines are at 23:59, Anywhere on Earth time zone;
                  deadlines are firm.
                </h3>
              </li>
              <li>
                <h3 className="text-box-lower">
                  Abstract submission  → <BsCalendar3Week />
                  {""} <small style={textStyle}>30 August  2023 </small>
                </h3>
               
              </li>
              <li>
                <h3 className="text-box-lower">
                  Paper submission  → <BsCalendar3Week />
                  {""} <small style={textStyle}> 4 September 2023 </small>
                </h3>
               
              </li>
              <li>
                <h3 className="text-box-lower">
                  Author notification → <BsCalendar3Week />
                  {""} <small style={textStyle}>4 October 2023</small>
                </h3>
                
              </li>
              <li>
                <h3 className="text-box-lower">
                  Camera ready  → <BsCalendar3Week />
                  {""} <small style={textStyle}>24 October 2023</small>
                </h3>
               
              </li>
              <li>
                <h3 className="text-box-lower">
                  Conference → <BsCalendar3Week />
                  {""} <small>8-10 November 2023</small>
                </h3>
                
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <Logo />
      </div>
      <div className="footer-m">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
