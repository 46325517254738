import React from "react";
import Navigation from "./Navigation/Navigation";
import SmoothScroll from "smooth-scroll";
import Header from "./Header/Header";
import "./Home/Home.css";
import Home from "./Home/Home";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Main = () => {






  return (
    <div>
      <Navigation />
      <Header />
      <Home />
      
    </div>
  );
};

export default Main;
