import React from "react";
import bnvkilogo from "../../assets/bnvkilogo-nobg.png";
import sikslogo from "../../assets/siks_from_eps.png";
import ortec from "../../assets/ortec.png";
import aiini from "../../assets/AI-Initiative-logo-rgb.png";
import asml from "../../assets/ASML_logo_bleu_PNG-format.png";
import Dyflexis from "../../assets/Logo Dyflexis_met payoff_large_RGB.png";
import aiportcenter from "../../assets/AI_PORT_CENTER_.png"
import "./Logo.css";
const Logo = () => {
  return (
    <>
      <div className="boxes-heading sponsor">
        <h1>Sponsors</h1>
      </div>
      <h2 className="logo-name-platinum">PLATINUM </h2>
      <div className="second-logos">
        <a href="http://www.bnvki.org/" target="_blank" rel="noreferrer">
          <img src={bnvkilogo} alt="bnvki" />
        </a>
        <div className="second-logos-siks">
          <a href="https://siks.nl/" target="_blank" rel="noreferrer">
            <img src={sikslogo} alt="siks" />
          </a>
        </div>
      </div>
      <h2 className="logo-name">GOLD </h2>

      <div className="main-logos">
      <a
          href="https://convergence.nl/ai-in-port-and-maritime-research/"
          target="_blank"
          rel="noreferrer"
          
        >
          <img src={aiportcenter} alt="aiportcenter" />
        </a>
        <a
          href="https://www.dyflexis.com/"
          target="_blank"
          rel="noreferrer"
          
        >
          <img src={Dyflexis} alt="Dyflexis" />
        </a>
        <a
          href="https://ortec.com/en"
          target="_blank"
          rel="noreferrer"
         
        >
          <img src={ortec} alt="ortec" />
        </a>

        <a
          href="https://www.tudelft.nl/en/ai/tu-delft-ai-initiative"
          target="_blank"
          rel="noreferrer"
        >
          <img src={aiini} alt="tudelft" />
        </a>
      </div>

      <h2 className="logo-name">SILVER </h2>
      <div className="main-logos">
        <a href="https://www.asml.com/en" target="_blank" rel="noreferrer">
          <img src={asml} alt="asml" />
        </a>
      </div>
    </>
  );
};

export default Logo;
