/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Navigation2.css";
import { CSSTransition } from "react-transition-group";
import hamb from "../../assets/hamburger.png";

export default function Navigation2() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // eslint-disable-next-line
  const [isTransparent, setTransparent] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1225px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0) {
      setTransparent(true);
    } else {
      setTransparent(false);
    }
  };
  const location = useLocation();
  window.addEventListener("scroll", handleScroll);

  return (
    <header className="Header2">
      <div>
        <a href="/">
          {" "}
          <img
            src={require("../../assets/BNAIC BeNeLearn 01-04.png")}
            className="Logo"
            alt="logo"
          />{" "}
        </a>
      </div>

      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={1}
        classNames="NavAnimation2"
        unmountOnExit
      >
        <nav className="Nav2" style={{ display: "grid" }}>
          {location.pathname !== "/" && <a href="/">← Home</a>}

          {location.pathname !== "/programme" && (
            <a href="/programme">Main Programme</a>
          )}

          {location.pathname !== "/papers-to-sessions" && (
            <a href="/papers-to-sessions">Papers to sessions</a>
          )}

          {location.pathname !== "/ppitches" && (
            <a href="/ppitches"> Poster pitches</a>
          )}

          {location.pathname !== "/posters" && <a href="/posters">Posters</a>}

          {location.pathname !== "/industrial-session" && (
            <a href="/industrial-session">Industrial session</a>
          )}

          {location.pathname !== "/invited-talks" && (
            <a href="/invited-talks">Invited talks</a>
          )}
          {location.pathname !== "/pre-proceedings" && (
            <a href="/pre-proceedings">Preproceedings</a>
          )}
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className="Burger2">
        <img src={hamb} alt="responsivemenu" />
      </button>
    </header>
  );
}
