import React from "react";
import "./Venue.css";
import Navigation from "../Navigation/Navigation";
import { GoLocation } from "react-icons/go";
import { BsFillBuildingsFill } from "react-icons/bs";
import { BiBusSchool } from "react-icons/bi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import "../CallforPapers/CallforPapers.css";
import building from "../../assets/main-cover2.jpg";
import dinnerphoto from "../../assets/heinde-en-ver-delft-1024x683.jpg";
import receptionphoto from "../../assets/0719_Next_Delft_©Ossip-scaled.jpg";
import Footer2 from "../FooterForOthers/Footer2";

const Venue = () => {
  return (
    <div className="venue">
      <Navigation />

      <div className="ven-heading">
        <h1>Main Venue</h1>
        <div className="venueImage">
          <img alt="3ME" src={building} />
        </div>

        <p>
          {" "}
          <BsFillBuildingsFill /> 3mE Building, TU Delft
        </p>

        <div className="r-icons">
          <a
            href="https://maps.app.goo.gl/exMSXdh8RSi6Ksbb9"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <GoLocation /> {""} Mekelweg 2, 2628 CD Delft{" "}
          </a>
        </div>

        <div className="ven">
          <h4>
            {" "}
            <AiOutlineInfoCircle /> Extra info{" "}
          </h4>
          <p>
            From Wednesday November 8 till Friday November 10, the conference
            will take place at the Delft University of Technology in the
            building of the Mechanical, Maritime and Materials Engineering
            faculty. There is a parking garage at the Cornelis Drebbelweg 1,
            Delft, which is P5 and the closest to the facility The building was
            opened for the faculty in 1957 with its main front in the campus
            Mekelpark. The building consists of four blocks perpendicular to the
            campus park, surrounded by a pond.
          </p>
          <h4>
            {" "}
            <BiBusSchool /> How to get there
          </h4>
          <p>
            To access the venue by public transport, take bus 40, 69, 174, or
            455 (Direction Rotterdam/Zoetermeer/Technopolis) from Delft Station
            or Delft Zuidpoort (next to the city centre) and exit at the TU Aula
            bus stop (but note bus diversions due to tram construction: please
            see{" "}
            <a href="http://9292.nl/" target="_blank" rel="noreferrer">
              9292.nl
            </a>{" "}
            ). There is a parking garage at the Cornelis Drebbelweg 1, Delft,
            which is P5 and the closest to the facility.
          </p>
          <h1>Reception venue</h1>
          <div className="venueImage">
            <img alt="3ME" src={receptionphoto} />
            <span>©Ossip van Duivenbode</span>
          </div>
          <p>
            {" "}
            <BsFillBuildingsFill /> NEXT Delft
          </p>

          <div className="r-icons">
            <a
              href="https://maps.app.goo.gl/vdUZoTjaUAuq9we7A"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <GoLocation /> {""} Molengraaffsingel 8, 2629 JD Delft{" "}
            </a>
            <h4>
              {" "}
              <AiOutlineInfoCircle /> Extra info{" "}
            </h4>
            <p>
              The conference welcome reception will take place at NEXT Delft.
              Here, we will enjoy some drinks on Wednesday November 8. NEXT is
              an impact driven, scale up community building in Delft. Located on
              the TU Delft campus, the building has over 10,000 of square meters
              of space for high-impact companies, scale-ups and fast-growing
              start-ups to continue to grow their business. The building is
              designed to accommodate businesses developing technological and
              sustainable innovations, with a focus on delivering social impact.
            </p>
            <h4>
              {" "}
              <BiBusSchool /> How to get there
            </h4>
            <p>
              With public transport, you can take bus 69 from either Delft city
              centre/station or from the conference venue in the direction of TU
              Campus and exit at the Molengraafsingel bus stop.
            </p>
            <h5>
              {" "}
              Be aware that it is a 20 minutes walk from the main venue 3mE
              building.
            </h5>
          </div>
        </div>
        <h1>Dinner venue</h1>
        <div className="venueImage">
          <img alt="3ME" src={dinnerphoto} />
        </div>
        <p>
          {" "}
          <BsFillBuildingsFill /> Heinde
        </p>

        <div className="r-icons">
          <a
            href="https://maps.app.goo.gl/zMvvpxzCz6g1LQb59"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <GoLocation /> {""} Zocherweg 9, 2613 ZT Delft{" "}
          </a>
        </div>
        <div className="ven">
          <h4>
            {" "}
            <AiOutlineInfoCircle /> Extra info{" "}
          </h4>
          <p>
            The conference dinner will take place at the Heinde restaurant. This
            restaurant is located in the northern part of Delft. Heine is a
            modern restaurant where you feel at home and your whole evening is
            taken care of. You are welcomed with open arms as if you were at
            home.
          </p>
          <h4>
            {" "}
            <BiBusSchool /> How to get there
          </h4>
          <p>
            From Delft station, you can take tram 1 in the direction of
            Schevingen and exit at the Nieuwe Plantage, from there it is a
            five-minute walk to the restaurant.
          </p>
        </div>
        <div className="ven">
          <h4>Time to Momo venue locations and hotels:</h4>
        </div>
      </div>
      <div className="timetomomo">
        <iframe
          title="timetomomo"
          src="https://www.timetomomo.com/en/visit/bnaic2023/?utm_medium=email&utm_source=organiser&utm_campaign=bnaic2023"
          width="100%"
          height="600px"
          loading="lazy"
        ></iframe>
      </div>
      <div className="footer-m">
        <Footer2 />
      </div>
    </div>
  );
};

export default Venue;
