import React from "react";
import { Routes, Route } from "react-router-dom";
import CallforPapers from "./components/CallforPapers/CallforPapers";
import Main from "./components/Main";
import Organisation from "./components/Organisation/Organisation";
import Program from "./components/Program/Program";
import Registration from "./components/Registration/Registration";
import Venue from "./components/Venue/Venue";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import Attending from "./components/Attending/Attending";
import FullProgram from "./components/FullProgram/FullProgram"
import PosterPitches from "./components/FullProgram/PosterPitches";
import Posters from "./components/FullProgram/Posters";
import Industrial from "./components/FullProgram/Industrial";
import InvitedSpeakers from "./components/FullProgram/InvitedSpeakers";
import Preproceedings from "./components/FullProgram/Preproceedings";


const TRACKING_ID = "G-P6HFH5DWBC";
ReactGA.initialize(TRACKING_ID);



function MainRouter() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/organisation" element={<Organisation />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/venue" element={<Venue />} />
      <Route path="/callforpapers" element={<CallforPapers />} />
      <Route path="/programme" element={<Program />} />
      <Route path="/attending" element={<Attending />} />
      <Route path="/papers-to-sessions" element={<FullProgram />} />
      <Route path="/ppitches" element={<PosterPitches />} />
      <Route path="/posters" element={<Posters />} />
      <Route path="/industrial-session" element={<Industrial />} />
      <Route path="/invited-talks" element={<InvitedSpeakers />} />
      <Route path="/pre-proceedings" element={<Preproceedings />} />
    </Routes>
  );
}

export default MainRouter;
