import React from "react";
import Navigation2 from "../Navigation2/Navigation2";
import "./Preproceedings.css";
import "../CallforPapers/CallforPapers.css";
import Footer2 from "../FooterForOthers/Footer2";
import paper4 from "../../PDFS/BNAICBENELEARN_2023_paper_4.pdf";
import paper5 from "../../PDFS/BNAICBENELEARN_2023_paper_5.pdf";
import paper6 from "../../PDFS/BNAICBENELEARN_2023_paper_6.pdf";
import paper7 from "../../PDFS/BNAICBENELEARN_2023_paper_7.pdf";

import paper10 from "../../PDFS/BNAICBENELEARN_2023_paper_10.pdf";

import paper13 from "../../PDFS/BNAICBENELEARN_2023_paper_13.pdf";
import paper14 from "../../PDFS/BNAICBENELEARN_2023_paper_14.pdf";
import paper15 from "../../PDFS/BNAICBENELEARN_2023_paper_15.pdf";
import paper16 from "../../PDFS/BNAICBENELEARN_2023_paper_16.pdf";

import paper18 from "../../PDFS/BNAICBENELEARN_2023_paper_18.pdf";

import paper20 from "../../PDFS/BNAICBENELEARN_2023_paper_20.pdf";

import paper22 from "../../PDFS/BNAICBENELEARN_2023_paper_22.pdf";
import paper23 from "../../PDFS/BNAICBENELEARN_2023_paper_23.pdf";
import paper24 from "../../PDFS/BNAICBENELEARN_2023_paper_24.pdf";
import paper25 from "../../PDFS/BNAICBENELEARN_2023_paper_25.pdf";

import paper27 from "../../PDFS/BNAICBENELEARN_2023_paper_27.pdf";

import paper29 from "../../PDFS/BNAICBENELEARN_2023_paper_29.pdf";
import paper30 from "../../PDFS/BNAICBENELEARN_2023_paper_30.pdf";
import paper31 from "../../PDFS/BNAICBENELEARN_2023_paper_31.pdf";

import paper33 from "../../PDFS/BNAICBENELEARN_2023_paper_33.pdf";
import paper34 from "../../PDFS/BNAICBENELEARN_2023_paper_34.pdf";

import paper36 from "../../PDFS/BNAICBENELEARN_2023_paper_36.pdf";
import paper37 from "../../PDFS/BNAICBENELEARN_2023_paper_37.pdf";
import paper38 from "../../PDFS/BNAICBENELEARN_2023_paper_38.pdf";

import paper40 from "../../PDFS/BNAICBENELEARN_2023_paper_40.pdf";
import paper41 from "../../PDFS/BNAICBENELEARN_2023_paper_41.pdf";
import paper42 from "../../PDFS/BNAICBENELEARN_2023_paper_42.pdf";
import paper43 from "../../PDFS/BNAICBENELEARN_2023_paper_43.pdf";
import paper44 from "../../PDFS/BNAICBENELEARN_2023_paper_44.pdf";
import paper45 from "../../PDFS/BNAICBENELEARN_2023_paper_45.pdf";
import paper46 from "../../PDFS/BNAICBENELEARN_2023_paper_46.pdf";
import paper47 from "../../PDFS/BNAICBENELEARN_2023_paper_47.pdf";

import paper49 from "../../PDFS/BNAICBENELEARN_2023_paper_49.pdf";
import paper50 from "../../PDFS/BNAICBENELEARN_2023_paper_50.pdf";
import paper51 from "../../PDFS/BNAICBENELEARN_2023_paper_51.pdf";
import paper52 from "../../PDFS/BNAICBENELEARN_2023_paper_52.pdf";
import paper53 from "../../PDFS/BNAICBENELEARN_2023_paper_53.pdf";

import paper55 from "../../PDFS/BNAICBENELEARN_2023_paper_55.pdf";
import paper56 from "../../PDFS/BNAICBENELEARN_2023_paper_56.pdf";
import paper57 from "../../PDFS/BNAICBENELEARN_2023_paper_57.pdf";
import paper58 from "../../PDFS/BNAICBENELEARN_2023_paper_58.pdf";
import paper59 from "../../PDFS/BNAICBENELEARN_2023_paper_59.pdf";
import paper60 from "../../PDFS/BNAICBENELEARN_2023_paper_60.pdf";
import paper61 from "../../PDFS/BNAICBENELEARN_2023_paper_61.pdf";
import paper62 from "../../PDFS/BNAICBENELEARN_2023_paper_62.pdf";
import paper63 from "../../PDFS/BNAICBENELEARN_2023_paper_63.pdf";
import paper64 from "../../PDFS/BNAICBENELEARN_2023_paper_64.pdf";
import paper65 from "../../PDFS/BNAICBENELEARN_2023_paper_65.pdf";
import paper66 from "../../PDFS/BNAICBENELEARN_2023_paper_66.pdf";
import paper67 from "../../PDFS/BNAICBENELEARN_2023_paper_67.pdf";
import paper68 from "../../PDFS/BNAICBENELEARN_2023_paper_68.pdf";

import paper70 from "../../PDFS/BNAICBENELEARN_2023_paper_70.pdf";

import paper73 from "../../PDFS/BNAICBENELEARN_2023_paper_73.pdf";
import paper74 from "../../PDFS/BNAICBENELEARN_2023_paper_74.pdf";

import paper77 from "../../PDFS/BNAICBENELEARN_2023_paper_77.pdf";

import paper79 from "../../PDFS/BNAICBENELEARN_2023_paper_79.pdf";
import paper80 from "../../PDFS/BNAICBENELEARN_2023_paper_80.pdf";
import paper81 from "../../PDFS/BNAICBENELEARN_2023_paper_81.pdf";

import paper83 from "../../PDFS/BNAICBENELEARN_2023_paper_83.pdf";
import paper84 from "../../PDFS/BNAICBENELEARN_2023_paper_84.pdf";
import paper85 from "../../PDFS/BNAICBENELEARN_2023_paper_85.pdf";
import paper86 from "../../PDFS/BNAICBENELEARN_2023_paper_86.pdf";
import paper87 from "../../PDFS/BNAICBENELEARN_2023_paper_87.pdf";

import paper89 from "../../PDFS/BNAICBENELEARN_2023_paper_89.pdf";
import paper90 from "../../PDFS/BNAICBENELEARN_2023_paper_90.pdf";
import paper91 from "../../PDFS/BNAICBENELEARN_2023_paper_91.pdf";
import paper92 from "../../PDFS/BNAICBENELEARN_2023_paper_92.pdf";
import paper93 from "../../PDFS/BNAICBENELEARN_2023_paper_93.pdf";

import paper96 from "../../PDFS/BNAICBENELEARN_2023_paper_96.pdf";
import paper97 from "../../PDFS/BNAICBENELEARN_2023_paper_97.pdf";
import paper98 from "../../PDFS/BNAICBENELEARN_2023_paper_98.pdf";
import paper99 from "../../PDFS/BNAICBENELEARN_2023_paper_99.pdf";

import paper101 from "../../PDFS/BNAICBENELEARN_2023_paper_101.pdf";
import paper102 from "../../PDFS/BNAICBENELEARN_2023_paper_102.pdf";
import paper103 from "../../PDFS/BNAICBENELEARN_2023_paper_103.pdf";
import paper104 from "../../PDFS/BNAICBENELEARN_2023_paper_104.pdf";

import paper106 from "../../PDFS/BNAICBENELEARN_2023_paper_106.pdf";
import paper107 from "../../PDFS/BNAICBENELEARN_2023_paper_107.pdf";
import paper108 from "../../PDFS/BNAICBENELEARN_2023_paper_108.pdf";

import paper111 from "../../PDFS/BNAICBENELEARN_2023_paper_111.pdf";
import paper112 from "../../PDFS/BNAICBENELEARN_2023_paper_112.pdf";
import paper113 from "../../PDFS/BNAICBENELEARN_2023_paper_113.pdf";

import paper115 from "../../PDFS/BNAICBENELEARN_2023_paper_115.pdf";
import paper116 from "../../PDFS/BNAICBENELEARN_2023_paper_116.pdf";
import paper117 from "../../PDFS/BNAICBENELEARN_2023_paper_117.pdf";
import paper118 from "../../PDFS/BNAICBENELEARN_2023_paper_118.pdf";
import paper119 from "../../PDFS/BNAICBENELEARN_2023_paper_119.pdf";
import paper120 from "../../PDFS/BNAICBENELEARN_2023_paper_120.pdf";
import paper121 from "../../PDFS/BNAICBENELEARN_2023_paper_121.pdf";
import paper122 from "../../PDFS/BNAICBENELEARN_2023_paper_122.pdf";
import paper123 from "../../PDFS/BNAICBENELEARN_2023_paper_123.pdf";
import paper124 from "../../PDFS/BNAICBENELEARN_2023_paper_124.pdf";
import paper125 from "../../PDFS/BNAICBENELEARN_2023_paper_125.pdf";

import paper127 from "../../PDFS/BNAICBENELEARN_2023_paper_127.pdf";
import paper128 from "../../PDFS/BNAICBENELEARN_2023_paper_128.pdf";
import paper129 from "../../PDFS/BNAICBENELEARN_2023_paper_129.pdf";
import paper130 from "../../PDFS/BNAICBENELEARN_2023_paper_130.pdf";
import paper131 from "../../PDFS/BNAICBENELEARN_2023_paper_131.pdf";
import paper132 from "../../PDFS/BNAICBENELEARN_2023_paper_132.pdf";
import paper133 from "../../PDFS/BNAICBENELEARN_2023_paper_133.pdf";
import paper134 from "../../PDFS/BNAICBENELEARN_2023_paper_134.pdf";
import paper135 from "../../PDFS/BNAICBENELEARN_2023_paper_135.pdf";
import paper136 from "../../PDFS/BNAICBENELEARN_2023_paper_136.pdf";
import paper137 from "../../PDFS/BNAICBENELEARN_2023_paper_137.pdf";
import paper138 from "../../PDFS/BNAICBENELEARN_2023_paper_138.pdf";
import paper139 from "../../PDFS/BNAICBENELEARN_2023_paper_139.pdf";
import paper140 from "../../PDFS/BNAICBENELEARN_2023_paper_140.pdf";
import paper141 from "../../PDFS/BNAICBENELEARN_2023_paper_141.pdf";
import maximum from "../../PDFS/Maximum_Entropy_Demographic_Parity_Vancompernolle_Vromman_Camera_Ready_231120.pdf"
import paper144 from "../../PDFS/BNAICBENELEARN_2023_paper_144.pdf";
import paper145 from "../../PDFS/BNAICBENELEARN_2023_paper_145.pdf";
import paper146 from "../../PDFS/BNAICBENELEARN_2023_paper_146.pdf";
import paper147 from "../../PDFS/BNAICBENELEARN_2023_paper_147.pdf";

const Preproceedings = () => {
  return (
    <div className="callforpapers">
      <Navigation2 />
      <div className="prep-heading">
        <h1>Type A: Original Research Papers</h1>
        <table className="table table-bordered table-dark custom-table ">
          <thead>
            <tr>
              <th>Title</th>
              <th>Authors</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Assessing aggressive driving behaviour using attention based
                models
              </td>
              <td>Jonathan Aechtner, Mirela Popa and Anna Wilbik</td>
              <td>
                <a href={paper37}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Forecast reconciliation for vaccine supply chain optimization
              </td>
              <td>
                Bhanu Angam, Alessandro Beretta, Eli De Poorter, Matthieu
                Duvinage and Daniel Peralta
              </td>
              <td>
                <a href={paper24}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Forecasting Train Arrival Delays on the Polatlı – Eskişehir
                High-Speed Line in Turkey
              </td>
              <td>Özgül Ardıç</td>
              <td>
                <a href={paper5}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Surrealistic-like Image Generation with Vision-Language Models
              </td>
              <td>Elif Ayten, Shuai Wang and Hjalmar Snoep</td>
              <td>
                <a href={paper84}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Exploring Knowledge Extraction Techniques for System Dynamics
                Modelling: Comparative Analysis and Considerations
              </td>
              <td>
                Roos Bakker, Gino Kalkman, Ioannis Tolios, Dominique Blok, Guido
                Veldhuis, Stephan Raaijmakers and Maaike de Boer
              </td>
              <td>
                <a href={paper68}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Reflection Machines: Introducing ‘Trias Reflecta’ to increase
                meaningful human control
              </td>
              <td>
                Chantal Banga, Marije Bekema, Kim Bladder, Loes Gennissen, Lotte
                de Groot and Pim Haselager
              </td>
              <td>
                <a href={paper18}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Improving performance of heart rate time series classification
                by grouping subjects
              </td>
              <td>
                Michael Beekhuizen, Arman Naseri, David Tax, Ivo van der Bilt
                and Marcel Reinders
              </td>
              <td>
                <a href={paper20}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Assessing the Optimality of Decentralized Inspection and
                Maintenance Policies for Stochastically Degrading Engineering
                Systems
              </td>
              <td>Prateek Bhustali and Charalampos P. Andriotis</td>
              <td>
                <a href={paper80}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Automatic Tagging Suggestion for Database Enrichment</td>
              <td>
                Emanuele Cuono Amoruso, Sietske Tacoma and Huib Aldewereld
              </td>
              <td>
                <a href={paper66}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Direct Exoplanet Detection Using L1 Norm Low-Rank Approximation
              </td>
              <td>
                Hazan Daglayan, Simon Vary, Valentin Leplat, Nicolas Gillis and
                Pierre Antoine Absil
              </td>
              <td>
                <a href={paper118}>Link</a>
              </td>
            </tr>

            <tr>
              <td>Learning to walk with a soft actor-critic approach</td>
              <td>Gijs de Jong, Leon Eshuijs and Arnoud Visser</td>
              <td>
                <a href={paper111}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Proportional Search Space Reduction: A Novel Metric for
                Cross-View Image Geo-Location
              </td>
              <td>Leon Debnath, Alexia Briassouli and Mirela Popa</td>
              <td>
                <a href={paper59}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Transfer Learning between Motor Imagery Datasets using Deep
                Learning - Validation of Framework and Comparison of Datasets
              </td>
              <td>Pierre Guetschel and Michael Tangermann</td>
              <td>
                <a href={paper133}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Deep Learning Classifiers to Reduce False Positives in Bone
                Lesion Segmentation from CT Scans of Multiple Myeloma
              </td>
              <td>
                Munirdin Jadikar, Martijn van Leeuwen, Thijs van Oudheusden,
                Sebastian Oei, Rik Kint, Bastiaan Steunenberg, Erik Ranschaert,
                Gerlof Bosma, Gorkem Saygili and Sharon Ong
              </td>
              <td>
                <a href={paper65}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Relevance feedback strategies for recall-oriented neural
                information retrieval
              </td>
              <td>Timo Kats, Peter van der Putten and Jan Scholtes</td>
              <td>
                <a href={paper134}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Enhancing Fake News Classification Through an
                Argumentation-Based Pipeline
              </td>
              <td>Mayla Kersten and Giovanni Sileno</td>
              <td>
                <a href={paper146}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Predicting Depression and Anxiety Risk in Dutch Neighborhoods
                from Street-View Images
              </td>
              <td>Nin Khodorivsko and Giacomo Spigler</td>
              <td>
                <a href={paper62}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                The Role of Personal Perspectives in Open-Domain Dialogue:
                Towards Enhanced Data Modelling and Long-term Memory
              </td>
              <td>
                Lea Krause, Selene Baez Santamaria, Lucia Donatelli and Piek
                Vossen
              </td>
              <td>
                <a href={paper23}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Defining the Optimal Degree of Abstraction in Explanations with
                Kolmogorov complexity
              </td>
              <td>Jan Lemeire and Stefan Buijsman</td>
              <td>
                <a href={paper38}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                A Comparative Study of Adaptive MCMC Schemes with Stopped and
                Diminishing Adaptation
              </td>
              <td>Bowen Liu, Edna Milgo, Nixon Ronoh and Bernard Manderick</td>
              <td>
                <a href={paper131}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Tree Variational Autoencoder for Code</td>
              <td>
                Vadim Liventsev, Sander de Bruin, Aki Härmä and Milan Petković
              </td>
              <td>
                <a href={paper141}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Investigating Imputation Methods for Handling Missing Data
              </td>
              <td>
                Jelle Maas, Job G.W.T. Römer, Işıl Baysal Erez and Maurice van
                Keulen
              </td>
              <td>
                <a href={paper73}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Potential Factors Leading to Popularity Unfairness in
                Recommender Systems: A User-Centered Analysis
              </td>
              <td>Masoud Mansoury, Finn Duijvestijn and Imane Mourabet</td>
              <td>
                <a href={paper115}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Incremental Profit per Conversion: a Response Transformation for
                Uplift Modeling in E-Commerce Promotions
              </td>
              <td>Hugo Manuel Proença and Felipe Moraes</td>
              <td>
                <a href={paper14}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Laser Learning Environment: A new environment for
                coordination-critical multi-agent tasks
              </td>
              <td>
                Yannick Molinghen, Raphaël Avalos, Mark Van Achter, Ann Nowé and
                Tom Lenaerts
              </td>
              <td>
                <a href={paper124}>Link</a>
              </td>
            </tr>

            <tr>
              <td>
                GroupEnc: encoder with group loss for global structure
                preservation
              </td>
              <td>David Novak, Sofie Van Gassen and Yvan Saeys</td>
              <td>
                <a href={paper46}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Model-based Clustering of Individuals' Ecological Momentary
                Assessment Data for Improving Forecasting Performance
              </td>
              <td>
                Mandani Ntekouli, Gerasimos Spanakis, Lourens Waldorp and Anne
                Roefs
              </td>
              <td>
                <a href={paper121}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Machine Learning Applications in Route Planning for Attended
                Home Delivery
              </td>
              <td>
                Thomas R. Visser, Ruggiero Seccia, Wouter Merkx, Wouter Kool and
                Leendert Kok
              </td>
              <td>
                <a href={paper79}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Why do penguins fly? Explaining Boolean Classifiers with
                Non-monotonic Background Theories
              </td>
              <td>Tjitze Rienstra</td>
              <td>
                <a href={paper77}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Vectorizing Timestamps by means of a Variational Autoencoder
              </td>
              <td>Gideon Rouwendaal and Xander Wilcke</td>
              <td>
                <a href={paper22}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Improving the Dark Web Classifier with Active Learning and
                Annotation Error Detection
              </td>
              <td>
                Pablo San Gil, Romana Pernisch, Eljo Haspels and Mark van
                Staalduinen
              </td>
              <td>
                <a href={paper93}>Link</a>
              </td>
            </tr>
            <tr>
              <td>No Imputation without Representation</td>
              <td>Oliver Urs Lenz, Daniel Peralta and Chris Cornelis</td>
              <td>
                <a href={paper145}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Human Recognition of Emotions Expressed by Human-like Avatars on
                2D Screens
              </td>
              <td>Erik van Haeringen, Marco Otte and Charlotte Gerritsen</td>
              <td>
                <a href={paper116}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Maximum Entropy Logistic Regression for Demographic Parity in
                Supervised Classification
              </td>
              
              <td>
                Flore Vancompernolle Vromman, Sylvain Courtain, Pierre Leleux,
                Constantin de Schaetzen, Eve Beghein, Alexia Kneip and Marco
                Saerens
              </td>
              <td>
                <a href={maximum}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Multi-Agent Communication using Difference Rewards Policy
                Gradients
              </td>
              <td>
                Simon Vanneste, Astrid Vanneste, Tom De Schepper, Siegfried
                Mercelis, Peter Hellinckx and Kevin Mets
              </td>
              <td>
                <a href={paper4}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Retrieval-based Question Answering with Passage Expansion using
                a Knowledge Graph
              </td>
              <td>Yiming Xu, Jan-Christoph Kalo and Benno Kruit</td>
              <td>
                <a href={paper99}>Link</a>
              </td>
            </tr>
          </tbody>
        </table>

        <h1>Type B: Encore Abstracts</h1>

        <table className="table table-bordered table-dark custom-table ">
          <thead>
            <tr>
              <th>Title</th>
              <th>Authors</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Trustworthy Artificial Intelligence in Medical Applications: A
                Mini Survey
              </td>
              <td>
                Mohsen Abbaspour Onari, Isel Grau, Marco S. Nobile and Yingqian
                Zhang
              </td>
              <td>
                <a href={paper74}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Expertise Trees Resolve Knowledge Limitations in Collective
                Decision-Making
              </td>
              <td>Axel Abels, Tom Lenaerts, Vito Trianni and Ann Nowé</td>
              <td>
                <a href={paper123}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                An extended abstract on the journal article: "Search-based task
                and motion planning for hybrid systems: Agile autonomous
                vehicles"
              </td>
              <td>
                Zlatan Ajanovic, Enrico Regolin, Barys Shyrokau, Hana Ćatić,
                Martin Horn and Antonella Ferrara
              </td>
              <td>
                <a href={paper140}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Effectiveness of User States, Demographics and Traits in
                Persuading to Quit Smoking
              </td>
              <td>Nele Albers, Mark A. Neerincx and Willem-Paul Brinkman</td>
              <td>
                <a href={paper25}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Robust Abstraction-Based Planning for Stochastic Dynamical
                Models with Epistemic Uncertainty
              </td>
              <td>
                Thom Badings, Licio Romao, Alessandro Abate and Nils Jansen
              </td>
              <td>
                <a href={paper104}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                The Effectiveness of Different Group Recommendation Strategies
                for Different Group Compositions
              </td>
              <td>
                Francesco Barile, Tim Draws, Oana Inel, Alisa Rieger, Amir
                Ebrahimi Fard, Rishav Hada and Nava Tintarev
              </td>
              <td>
                <a href={paper67}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Benchmarking Surrogate-based Optimisation Algorithms on
                Expensive Black-box Functions
              </td>
              <td>
                Laurens Bliek, Arthur Guijt, Rickard Karlsson, Sicco Verwer and
                Mathijs de Weerdt
              </td>
              <td>
                <a href={paper101}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Image Processing Perspectives of X-Ray Fluorescence Data in
                Cultural Heritage Sciences
              </td>
              <td>
                Henry Chopp, Alicia McGeachy, Matthias Alfeld, Oliver Cossairt,
                Marc Walton and Aggelos Katsaggelos
              </td>
              <td>
                <a href={paper125}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                A Binning Approach for Predicting Long-Term Prognosis in
                Multiple Sclerosis
              </td>
              <td>Robbe D'Hondt, Sinéad Moylett, An Goris and Celine Vens</td>
              <td>
                <a href={paper119}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Neural Probabilistic Logic Programming in Discrete-Continuous
                Domains
              </td>
              <td>
                Lennert De Smet, Pedro Zuidberg Dos Martires, Robin Manhaeve,
                Giuseppe Marra, Angelika Kimmig and Luc De Raedt
              </td>
              <td>
                <a href={paper135}>Link</a>
              </td>
            </tr>

            <tr>
              <td>
                BELLATREX: Building Explanations through a LocaLly AccuraTe Rule
                EXtractor
              </td>
              <td>
                Klest Dedja, Felipe Kenji Nakano, Konstantinos Pliakos and
                Celine Vens
              </td>
              <td>
                <a href={paper61}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Fair and Optimal Decision Trees: A Dynamic Programming Approach
              </td>
              <td>Emir Demirović, Koos Van Der Linden and Mathijs De Weerdt</td>
              <td>
                <a href={paper137}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Rights and Practical Reasoning in Deontic Logic</td>
              <td>
                Huimin Dong, Dragan Doder, Xu Li, Réka Markovich, Leon van der
                Torre and Marc van Zee
              </td>
              <td>
                <a href={paper130}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Reasoning About Group Responsibility for Taking Risks</td>
              <td>
                Maksim Gladyshev, Natasha Alechina, Mehdi Dastani and Dragan
                Doder
              </td>
              <td>
                <a href={paper139}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Establishing Shared Query Understanding in an Open Multi-Agent
                System Extended Abstract
              </td>
              <td>Nikolaos Kondylidis, Ilaria Tiddi and Annette Ten Teije</td>
              <td>
                <a href={paper7}>Link</a>
              </td>
            </tr>

            <tr>
              <td>The EURO Meets NeurIPS 2022 Vehicle Routing Competition</td>
              <td>
                Wouter Kool, Laurens Bliek, Danilo Numeroso, Yingqian Zhang, Tom
                Catshoek, Kevin Tierney, Thibaut Vidal and Joaquim Gromicho
              </td>
            </tr>
            <tr>
              <td>
                Recursive Small-Step Multi-Agent A*: A Faster Exact Solver for
                Dec-POMDPs
              </td>
              <td>
                Wietze Koops, Nils Jansen, Sebastian Junges and Thiago Simão
              </td>
              <td>
                <a href={paper138}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Minimalist Entity Disambiguation for Mid-Resource Languages
              </td>
              <td>Benno Kruit</td>
              <td>
                <a href={paper10}>Link</a>
              </td>
            </tr>

            <tr>
              <td>
                Motivating explanations in Bayesian networks using
                MAP-independence
              </td>
              <td>Johan Kwisthout</td>
              <td>
                <a href={paper36}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Tomea: an Explainable Method for Comparing Morality Classifiers
                across Domains
              </td>
              <td>
                Enrico Liscio, Oscar Araque, Lorenzo Gatti, Ionut
                Constantinescu, Catholijn M. Jonker, Kyriaki Kalimeri and
                Pradeep K. Murukannaiah
              </td>
              <td>
                <a href={paper29}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Fully Autonomous Programming with Large Language Models</td>
              <td>
                Vadim Liventsev, Anastasiia Grishina, Aki Härmä and Leon Moonen
              </td>
            </tr>
            <tr>
              <td>
                Fostering Appropriate Trust in AI Agents through
                Integrity-Centered Explanations
              </td>
              <td>
                Siddharth Mehrotra, Carolina Centeio Jorge, Catholijn M. Jonker
                and Myrthe L. Tielman
              </td>
              <td>
                <a href={paper96}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Abstract: Color-Dependent Prediction Stability of Popular CNN
                Image Classification Architectures
              </td>
              <td>
                Laurent Mertens, Elahe Yargholi, Jan Van den Stock, Hans Op de
                Beeck and Joost Vennekens
              </td>
              <td>
                <a href={paper34}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Causal Entropy and Information Gain for Measuring Causal Control
              </td>
              <td>
                Francisco N. F. Q. Simoes, Mehdi Dastani and Thijs van Ommen
              </td>
              <td>
                <a href={paper98}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Encore Abstract: Presumably Correct Decision Sets</td>
              <td>
                Gonzalo Nápoles, Isel Grau, Agnieszka Jastrzebska and Yamisleydi
                Salgueiro
              </td>
              <td>
                <a href={paper70}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Linear CNNs Discover the Statistical Structure of the Dataset
                Using Only the Most Dominant Frequencies
              </td>
              <td>Hannah Pinson, Joeri Lenaerts and Vincent Ginis</td>
              <td>
                <a href={paper42}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Learning to Adapt Genetic Algorithms for Multi-Objective
                Flexible Job Shop Scheduling Problems
              </td>
              <td>
                Robbert Reijnen, Zaharah Bukhsh, Yingqian Zhang and Mateusz
                Guzek
              </td>
              <td>
                <a href={paper132}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Graph-Time Convolutional Neural Networks: Architecture and
                Theoretical Analysis
              </td>
              <td>Mohammad Sabbaqi and Elvin Isufi</td>
              <td>
                <a href={paper136}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Safe Policy Improvement in POMDPs</td>
              <td>Marnix Suilen, Thiago D. Simão and Nils Jansen</td>
              <td>
                <a href={paper108}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Taking a Shortcut Through Phase Space: A Multi-task Learning
                Approach to Solving Differential Equations
              </td>
              <td>Floriano Tori and Vincent Ginis</td>
              <td>
                <a href={paper45}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Determinants of physical activity behaviour change in (online)
                interventions, and gender‑specific differences: a Bayesian
                network model
              </td>
              <td>
                Simone Tummers, Arjen Hommersom, Lilian Lechner, Roger Bemelmans
                and Catherine Bolman
              </td>
              <td>
                <a href={paper43}>Link</a>
              </td>
            </tr>

            <tr>
              <td>
                Contextual Qualitative Deterministic Models for Self-Learning
                Embodied Agents
              </td>
              <td>Marco Van Cleemput and Jan Lemeire</td>
              <td>
                <a href={paper16}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Three types of incremental learning</td>
              <td>Gido van de Ven, Tinne Tuytelaars and Andreas Tolias</td>
              <td>
                <a href={paper30}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Language Games Meet Multi-Agent Reinforcement Learning: A Case
                Study for the Naming Game
              </td>
              <td>
                Paul Van Eecke, Katrien Beuls, Jérôme Botoko Ekila and Roxana
                Rădulescu
              </td>
              <td>
                <a href={paper44}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Beyond Flattening: Hilbert Space-filling Curves for Enhanced
                Locality Preservation in CNNs
              </td>
              <td>Bert Verbruggen and Vincent Ginis</td>
              <td>
                <a href={paper90}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Neuro-Symbolic Procedural Semantics for Reasoning-Intensive
                Visual Dialogue Tasks
              </td>
              <td>
                Lara Verheyen, Jérôme Botoko Ekila, Jens Nevens, Paul Van Eecke
                and Katrien Beuls
              </td>
              <td>
                <a href={paper33}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Faster and more accurate pathogenic combination predictions with
                VarCoPP2.0
              </td>
              <td>
                Nassim Versbraegen, Barbara Gravel, Charlotte Nachtegael,
                Alexandre Renaux, Emma Verkinderen, Ann Nowé, Tom Lenaerts and
                Sofia Papadimitriou
              </td>
            </tr>
            <tr>
              <td>
                Optimal Decision Tree Policies for Markov Decision Processes
              </td>
              <td>Daniël Vos and Sicco Verwer</td>
              <td>
                <a href={paper15}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                "How sweet are your strawberries?": predicting sugariness using
                non-destructive and affordable hardware
              </td>
              <td>Junhan Wen, Thomas Abeel and Mathijs de Weerdt</td>
              <td>
                <a href={paper13}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Convolutional Filtering and Learning on Simplicial Complexes
              </td>
              <td>Maosheng Yang and Elvin Isufi</td>
              <td>
                <a href={paper103}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Training and Transferring Safe Policies in Reinforcement
                Learning
              </td>
              <td>
                Qisong Yang, Thiago D. Simão, Nils Jansen, Simon Tindemans and
                Matthijs T. J. Spaan
              </td>
              <td>
                <a href={paper106}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Accurate online training of dynamical spiking neural networks
                through Forward Propagation Through Time
              </td>
              <td>Bojian Yin, Federico Corradi and Sander Bohte</td>
              <td>
                <a href={paper107}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Synchromodal freight transport re-planning under service time
                uncertainty: An online model-assisted reinforcement learning
              </td>
              <td>Yimeng Zhang, Rudy R. Negenborn and Bilge Atasoy</td>
              <td>
                <a href={paper50}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Continuous Communication with Factorized Policy Gradients in
                Multi-agent Deep Reinforcement Learning
              </td>
              <td>Changxi Zhu, Mehdi Dastani and Shihan Wang</td>
              <td>
                <a href={paper147}>Link</a>
              </td>
            </tr>
          </tbody>
        </table>
        <h1>Type C: Demo Papers</h1>
        <table className="table table-bordered table-dark custom-table ">
          <thead>
            <tr>
              <th>Title</th>
              <th>Authors</th>
              <th>Link</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                Training child helpline counsellors with a BDI-based
                conversational agent
              </td>
              <td>
                Mohammed Al Owayyed, Sharon Grundmann, Merijn Bruijnes and
                Willem-Paul Brinkman
              </td>
              <td>
                <a href={paper117}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Using Integrated and Enriched Linked Data for Ukraine Resilience
              </td>
              <td>Manar Attar, Shuai Wang, Ronald Siebes and Eirik Kultorp</td>
              <td>
                <a href={paper83}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Demonstrating reinforcement-learned gaits with two small
                quadrupeds
              </td>
              <td>Leon Eshuijs, Gijs de Jong and Arnoud Visser</td>
              <td>
                <a href={paper144}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Creating a Pipeline to Graphically Design and Execute Spiking
                Network Algorithms
              </td>
              <td>Niels Van Harten, Arne Diehl and Johan Kwisthout</td>
              <td>
                <a href={paper47}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Demonstration Abstracts of a Mobile Application for Near
                Real-time Strawberry Quality Prediction
              </td>
              <td>
                Junhan Wen, Andrei Dascalu, Dragomir Drashkov, Marinski, Bhatti,
                Herrebout and Michael Weinmann
              </td>
              <td>
                <a href={paper27}>Link</a>
              </td>
            </tr>
          </tbody>
        </table>
        <h1>Type D: Student Thesis Abstracts</h1>
        <table className="table table-bordered table-dark custom-table ">
          <thead>
            <tr>
              <th>Title</th>
              <th>Authors</th>
              <th>Link</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Graph Neural Networks for Chess</td>
              <td>Saleh Alwer, Aske Plaat and Walter Kosters</td>
              <td>
                <a href={paper6}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Knowledge graph embeddings for the prediction of pathogenic gene
                pairs
              </td>
              <td>
                Inas Bosch, Tom Lenaerts, Barbara Gravel and Alexandre Renaux
              </td>
              <td>
                <a href={paper120}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                The Underlying Belief Model of Uncertain Partially Observable
                Markov Decision Processes
              </td>
              <td>Eline Bovy</td>
              <td>
                <a href={paper122}>Link</a>
              </td>
            </tr>
            <tr>
              <td>A Benchmark for Recipe Understanding in Autonomous Agents</td>
              <td>Robin De Haes</td>
              <td>
                <a href={paper51}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Enhancing Learning in Sparse Neural Networks: A Hebbian Learning
                Approach
              </td>
              <td>Alexander de Ranitz, Ardion Beldad and Elena Mocanu</td>
              <td>
                <a href={paper81}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                IC-Errors: Interactive Identification and Correction of Label
                Errors in High-Dimensional Data
              </td>
              <td>Jacob Demuynck</td>
            </tr>
            <tr>
              <td>
                The performance of a robust Bayesian approach to the 20
                questions game under different patterns of noise
              </td>
              <td>Anton Donle</td>
            </tr>
            <tr>
              <td>Online Planning in MPOMDPs with Many Agents</td>
              <td>Maris Galesloot</td>
              <td>
                <a href={paper56}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Genetic Classification of Accented Speech from Audio Recordings
                of Spoken Nonsense Words
              </td>
              <td>Gregory Go, Paula Roncaglia and Sharon Ong</td>
              <td>
                <a href={paper127}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Assessing Convexity Characteristics of Learning Curves</td>
              <td>Kristian Gogora and Tom Viering</td>
              <td>
                <a href={paper128}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Reducing Bias in the ASR 'Whisper' Model: A Synthetic Speech
                Data Approach
              </td>
              <td>Lisanne Henzen and Pascal Wiggers</td>
              <td>
                <a href={paper64}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Mining meaning: A semantic-aware approach to sentence embedding.
              </td>
              <td>Gabriel Hoogerwerf</td>
              <td>
                <a href={paper91}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Optimal Control of Lettuce Greenhouse Horticulture using
                Model-Free Reinforcement Learning
              </td>
              <td>Yde Jansen, Sjoerd Boersma and Natasha Alechina</td>
              <td>
                <a href={paper86}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Active Measuring in Uncertain Environments</td>
              <td>Merlijn Krale</td>
              <td>
                <a href={paper58}>Link</a>
              </td>
            </tr>

            <tr>
              <td>
                Predicting the performance of an algorithm for the Capacitated
                Vehicle Routing Problem with Time Windows
              </td>
              <td>
                Muratzhan Kyranbay, Johan Rogiers, Roel Leus and Tim Hellemans
              </td>
            </tr>
            <tr>
              <td>
                Bottom-Up Search: A Distance-Based Search Strategy for
                Supervised Local Pattern Mining on Multi-Dimensional Target
                Spaces
              </td>
              <td>Bart L.T.J. Engelen and Wouter Duivesteijn</td>
              <td>
                <a href={paper40}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Generative Expression Constrained Knowledge-based decoding for
                Open data
              </td>
              <td>Lucas Lageweg</td>
            </tr>
            <tr>
              <td>Generating and Comparing AI-generated Biblical Images</td>
              <td>Hidde Makimei, Shuai Wang and Willem Peursen</td>
            </tr>
            <tr>
              <td>Learning Customer Behavior in Online Grocery Services</td>
              <td>
                Marloes Middelweerd, Wouter Merkx, Pedro Zattoni Scroccaro and
                Bilge Atasoy
              </td>
              <td>
                <a href={paper112}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Mitigating Intersectional Bias in Toxic Comment Classification
                Models through Data Balancing
              </td>
              <td>Oscar Oosterling</td>
              <td>
                <a href={paper85}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Advancing In-vivo Vulvar Tumour Detection: Combining AI and
                Hyperspectral Imaging
              </td>
              <td>Parsa Paiman, Nick van de Berg and Heleen van Beekhuizen</td>
              <td>
                <a href={paper60}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Generative AI descriptors for precursor microRNA</td>
              <td>Marko Petkovic and Vlado Menkovski</td>
              <td>
                <a href={paper55}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Detecting and Mitigating Goal Misgeneralisation with Logical
                Interpretability Tools
              </td>
              <td>Coen Rouwmaat</td>
            </tr>
            <tr>
              <td>Robust Deep Spectral Clustering</td>
              <td>Andrei Rykov and Sibylle Hess</td>
              <td>
                <a href={paper63}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Inbox Harmony: A Recommendation System to Manage and Organise
                Emails Based on PIM Principles
              </td>
              <td>Anisha Sachdeva and Beat Signer</td>
              <td>
                <a href={paper87}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Exceptional In So Many Domains: Exploring Exceptional Model
                Mining in Unstructured Data
              </td>
              <td>Niels Schelleman and Wouter Duivesteijn</td>
              <td>
                <a href={paper41}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Conformal Multistep-Ahead Multivariate Time-Series Forecasting
              </td>
              <td>Filip Schlembach</td>
              <td>
                <a href={paper113}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                From Novice to Composer: Utilizing Machine Learning to
                Facilitate Music Creation with MIDI Generation and Sample
                Extraction
              </td>
              <td>Erik Slingerland and Marcio Fuckner</td>
              <td>
                <a href={paper49}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Learning to Be Efficient and Fair for Collaborative Order
                Picking
              </td>
              <td>
                Igor Smit, Zaharah Bukhsh, Yingqian Zhang and Mykola Pechenizkiy
              </td>
              <td>
                <a href={paper31}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Emergent Theory of Mind in Large Language Models</td>
              <td>Maksim Terentev</td>
              <td>
                <a href={paper129}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                The online optimization of brain-computer interface stimulus
                parameters, a simulation
              </td>
              <td>Chiara Thöni</td>
              <td>
                <a href={paper89}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Importance Weighting Domain Adaptation Beyond Specific Target
                Domains
              </td>
              <td>Andrei C. Tociu, Yasin I. Tepeli and Joana P. Gonçalves</td>
              <td>
                <a href={paper97}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Towards Responsible AI Code Development: A Six-Value Process
                Model for Junior and Novice AI Programmers
              </td>
              <td>Rick van Kersbergen and Saskia Robben</td>
              <td>
                <a href={paper52}>Link</a>
              </td>
            </tr>
            <tr>
              <td>Towards Certified MaxSAT Solving</td>
              <td>Dieter Vandesande and Bart Bogaerts</td>
              <td>
                <a href={paper57}>Link</a>
              </td>
            </tr>

            <tr>
              <td>A general purpose framework for fairness in job hiring</td>
              <td>
                Sam Vanspringel, Alexandra Cimpean, Pieter Libin and Ann Nowé
              </td>
              <td>
                <a href={paper92}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Deep Domain Adaptation without Access to a Specific Target
                Domain
              </td>
              <td>Emiel Witting, Yasin I. Tepeli and Joana P. Gonçalves</td>
              <td>
                <a href={paper53}>Link</a>
              </td>
            </tr>
            <tr>
              <td>
                Using Sink States to Learn from Infrequent Data in an Automaton
                Model
              </td>
              <td>
                Jegor Zelenjak, Ioan-Cristian Oprea, Alexandru Dumitriu, Sicco
                Verwer and Azqa Nadeem
              </td>
              <td>
                <a href={paper102}>Link</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </div>
  );
};

export default Preproceedings;
