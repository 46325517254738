import React from "react";
import "./Posters.css";
import "./PosterPitches.css";
import { useState } from "react";
import Navigation2 from "../Navigation2/Navigation2";
import Footer2 from "../FooterForOthers/Footer2";

const Posters = () => {
  const [selectedDate, setselectedDate] = useState("FRI 10 NOV");

  const handleDateClick = (date) => {
    setselectedDate(date);
  };

  const dataTHU = [
    {
      name: "Saleh Alwer, Aske Plaat and Walter Kosters",
      task: "Graph Neural Networks for Chess",
      thesis: "Thesis paper",
    },
    {
      name: "Robin De Haes",
      task: "A Benchmark for Recipe Understanding in Autonomous Agents",
      thesis: "Thesis paper",
    },
    {
      name: "Bart L.T.J. Engelen and Wouter Duivesteijn",
      task: "Bottom-Up Search: A Distance-Based Search Strategy for Supervised Local Pattern Mining on Multi-Dimensional Target Spaces",
      thesis: "Thesis paper",
    },
    {
      name: "Erik Slingerland and Marcio Fuckner",
      task: "From Novice to Composer: Utilizing Machine Learning to Facilitate Music Creation with MIDI Generation and Sample Extraction",
      thesis: "Thesis paper",
    },
    {
      name: "Emiel Witting, Yasin I. Tepeli and Joana P. Gonçalves",
      task: "Deep Domain Adaptation without Access to a Specific Target Domain",
      thesis: "Thesis paper",
    },
    {
      name: "Dieter Vandesande and Bart Bogaerts",
      task: "Towards Certified MaxSAT Solving",
      thesis: "Thesis paper",
    },
    {
      name: "Andrei Rykov and Sibylle Hess",
      task: "Robust Deep Spectral Clustering",
      thesis: "Thesis paper",
    },
    {
      name: "Alexander de Ranitz, Ardion Beldad and Elena Mocanu",
      task: "Enhancing Learning in Sparse Neural Networks: A Hebbian Learning Approach",
      thesis: "Thesis paper",
    },
    {
      name: "Anisha Sachdeva and Beat Signer",
      task: "Inbox Harmony: A Recommendation System to Manage and Organise Emails Based on PIM Principles",
      thesis: "Thesis paper",
    },

    {
      name: "Gabriel Hoogerwerf",
      task: "Mining meaning: A semantic-aware approach to sentence embedding.",
      thesis: "Thesis paper",
    },
    {
      name: "Jegor Zelenjak, Ioan-Cristian Oprea, Alexandru Dumitriu, Sicco Verwer and Azqa Nadeem",
      task: "Using Sink States to Learn from Infrequent Data in an Automaton Model",
      thesis: "Thesis paper",
    },
    {
      name: "Inas Bosch, Tom Lenaerts, Barbara Gravel and Alexandre Renaux",
      task: "Knowledge graph embeddings for the prediction of pathogenic gene pairs",
      thesis: "Thesis paper",
    },
    {
      name: "Gregory Go, Paula Roncaglia and Sharon Ong",
      task: "Genetic Classification of Accented Speech from Audio Recordings of Spoken Nonsense Words",
      thesis: "Thesis paper",
    },
    {
      task: "No Imputation without Representation",
      name: "Oliver Urs Lenz, Daniel Peralta and Chris Cornelis",
    },
    {
      task: "Automatic Tagging Suggestion for Database Enrichment",
      name: "Emanuele Cuono Amoruso, Sietske Tacoma and Huib Aldewereld",
    },
    {
      task: "Transfer Learning between Motor Imagery Datasets using Deep Learning - Validation of Framework and Comparison of Datasets",
      name: "Pierre Guetschel and Michael Tangermann",
    },
    {
      task: "Investigating Imputation Methods for Handling Missing Data",
      name: "Jelle Maas, Job G.W.T. Römer, Işıl Baysal Erez and Maurice van Keulen",
    },
    {
      task: "Expertise Trees Resolve Knowledge Limitations in Collective Decision-Making",
      name: "Axel Abels, Tom Lenaerts, Vito Trianni and Ann Nowé",
    },
    {
      task: "Fair and Optimal Decision Trees: A Dynamic Programming Approach",
      name: "Emir Demirović, Koos Van Der Linden and Mathijs De Weerdt",
    },
    {
      task: "Optimal Decision Tree Policies for Markov Decision Processes",
      name: "Daniël Vos and Sicco Verwer",
    },
    {
      task: "Learning to walk with a soft actor-critic approach",
      name: "Gijs de Jong, Leon Eshuijs and Arnoud Visser",
    },
    {
      task: "Contextual Qualitative Deterministic Models for Self-Learning Embodied Agents",
      name: "Marco Van Cleemput and Jan Lemeire",
    },
    {
      task: "An extended abstract on the journal article: 'Search-based task and motion planning for hybrid systems: Agile autonomous vehicles'",
      name: "Zlatan Ajanovic, Enrico Regolin, Barys Shyrokau, Hana Ćatić, Martin Horn and Antonella Ferrara",
    },
    {
      task: "Graph-Time Convolutional Neural Networks: Architecture and Theoretical Analysis",
      name: "Mohammad Sabbaqi and Elvin Isufi",
    },
    {
      task: "Convolutional Filtering and Learning on Simplicial Complexes",
      name: "Maosheng Yang and Elvin Isufi",
    },
    {
      task: "Beyond Flattening: Hilbert Space-filling Curves for Enhanced Locality Preservation in CNNs",
      name: "Bert Verbruggen and Vincent Ginis",
    },
    {
      task: "Linear CNNs Discover the Statistical Structure of the Dataset Using Only the Most Dominant Frequencies",
      name: "Hannah Pinson, Joeri Lenaerts and Vincent Ginis",
    },
    {
      task: "Incremental Profit per Conversion: a Response Transformation for Uplift Modeling in E-Commerce Promotions",
      name: "Hugo Manuel Proença and Felipe Moraes",
    },
    {
      task: "Causal Entropy and Information Gain for Measuring Causal Control",
      name: "Francisco N. F. Q. Simoes, Mehdi Dastani and Thijs van Ommen",
    },
    {
      task: "A Comparative Study of Adaptive MCMC Schemes with Stopped and Diminishing Adaptation",
      name: "Bowen Liu, Edna Milgo, Nixon Ronoh and Bernard Manderick",
    },
    {
      task: "Encore Abstract: Presumably Correct Decision Sets",
      name: "Gonzalo Nápoles, Isel Grau, Agnieszka Jastrzebska and Yamisleydi Salgueiro",
    },
    {
      task: "Fully Autonomous Programming with Large Language Models",
      name: "Vadim Liventsev, Anastasiia Grishina, Aki Härmä and Leon Moonen",
    },
    {
      task: "Active Learning - Improving the Dark Web Classifier Based on Expert Input",
      name: "Pablo San Gil, Romana Pernisch, Eljo Haspels and Mark van Staalduinen",
    },
    {
      task: "Tree Variational Autoencoder for Code",
      name: "Vadim Liventsev, Sander de Bruin, Aki Härmä and Milan Petković",
    },
    {
      task: "Exploring Knowledge Extraction Techniques for System Dynamics Modelling: Comparative Analysis and Considerations",
      name: "Roos Bakker, Gino Kalkman, Ioannis Tolios, Dominique Blok, Guido Veldhuis, Stephan Raaijmakers and Maaike de Boer",
    },
    {
      task: "Trustworthy Artificial Intelligence in Medical Applications: A Mini Survey",
      name: "Mohsen Abbaspour Onari, Isel Grau, Marco S. Nobile and Yingqian Zhang",
    },
    {
      task: "Fostering Appropriate Trust in AI Agents through Integrity-Centered Explanations",
      name: "Siddharth Mehrotra, Carolina Centeio Jorge, Catholijn M. Jonker and Myrthe L. Tielman",
    },
    {
      task: "Tomea: an Explainable Method for Comparing Morality Classifiers across Domains",
      name: "Enrico Liscio, Oscar Araque, Lorenzo Gatti, Ionut Constantinescu, Catholijn M. Jonker, Kyriaki Kalimeri and Pradeep K. Murukannaiah",
    },
    {
      task: "Reflection Machines: Introducing ‘Trias Reflecta’ to increase meaningful human control",
      name: "Chantal Banga, Marije Bekema, Kim Bladder, Loes Gennissen, Lotte de Groot and Pim Haselager",
    },
  ];

  const dataWED = [
    {
      name: "Jacob Demuynck",
      task: "IC-Errors: Interactive Identification and Correction of Label Errors in High-Dimensional Data ",
      thesis: "Thesis paper",
    },
    {
      name: "Lucas Lageweg",
      task: "Generative Expression Constrained Knowledge-based decoding for Open data ",
      thesis: "Thesis paper",
    },
    {
      name: "Anton Donle",
      task: "The performance of a robust Bayesian approach to the 20 questions game under different patterns of noise ",
      thesis: "Thesis paper",
    },
    {
      name: "Rick van Kersbergen and Saskia Robben",
      task: "Towards Responsible AI Code Development: A Six-Value Process Model for Junior and Novice AI Programmers ",
      thesis: "Thesis paper",
    },
    {
      name: "Maris Galesloot",
      task: "Online Planning in MPOMDPs with Many Agents ",
      thesis: "Thesis paper",
    },
    {
      name: "Parsa Paiman, Nick van de Berg and Heleen van Beekhuizen",
      task: "Advancing In-vivo Vulvar Tumour Detection: Combining AI and Hyperspectral Imaging ",
      thesis: "Thesis paper",
    },
    {
      name: "Muratzhan Kyranbay, Johan Rogiers, Roel Leus and Tim Hellemans",
      task: "Predicting the performance of an algorithm for the Capacitated Vehicle Routing Problem with Time Windows ",
      thesis: "Thesis paper",
    },
    {
      name: "Yde Jansen, Sjoerd Boersma and Natasha Alechina",
      task: "Optimal Control of Lettuce Greenhouse Horticulture using Model-Free Reinforcement Learning ",
      thesis: "Thesis paper",
    },
    {
      name: "Chiara Thöni",
      task: "The online optimization of brain-computer interface stimulus parameters, a simulation ",
      thesis: "Thesis paper",
    },

    {
      name: "Filip Schlembach",
      task: "Conformal Multistep-Ahead Multivariate Time-Series Forecasting ",
      thesis: "Thesis paper",
    },
    {
      name: "Hidde Makimei, Shuai Wang and Willem Peursen",
      task: "Generating and Comparing AI-generated Biblical Images ",
      thesis: "Thesis paper",
    },
    {
      name: "Maksim Terentev",
      task: "Emergent Theory of Mind in Large Language Models ",
      thesis: "Thesis paper",
    },
    {
      name: "Floriano Tori and Vincent Ginis",
      task: "Taking a Shortcut Through Phase Space: A Multi-task Learning Approach to Solving Differential Equations",
    },
    {
      name: "Bojian Yin, Federico Corradi and Sander Bohte",
      task: "Accurate online training of dynamical spiking neural networks through Forward Propagation Through Time",
    },
    {
      task: "Establishing Shared Query Understanding in an Open Multi-Agent System Extended Abstract",
      name: "Nikolaos Kondylidis, Ilaria Tiddi, Annette Ten Teije",
    },
    {
      name: "Gido van de Ven, Tinne Tuytelaars and Andreas Tolias",
      task: "Three types of incremental learning",
    },
    {
      name: "Flore Vancompernolle Vromman, Sylvain Courtain, Pierre Leleux, Constantin de Schaetzen, Eve Beghein, Alexia Kneip and Marco Saerens",
      task: "Maximum Entropy Logistic Regression for Demographic Parity in Supervised Classification",
    },
    {
      name: "Laurens Bliek, Arthur Guijt, Rickard Karlsson, Sicco Verwer and Mathijs de Weerdt",
      task: "Benchmarking Surrogate-based Optimisation Algorithms on Expensive Black-box Functions",
    },
    {
      name: "Thom Badings, Licio Romao, Alessandro Abate and Nils Jansen",
      task: "Robust Abstraction-Based Planning for Stochastic Dynamical Models with Epistemic Uncertainty",
    },
    {
      name: "Prateek Bhustali and Charalampos P. Andriotis",
      task: "Assessing the Optimality of Decentralized Inspection and Maintenance Policies for Stochastically Degrading Engineering Systems",
    },
    {
      name: "Yimeng Zhang, Rudy R. Negenborn and Bilge Atasoy",
      task: "Synchromodal freight transport re-planning under service time uncertainty: An online model-assisted reinforcement learning",
    },
    {
      name: "Leon Debnath, Alexia Briassouli and Mirela Popa",
      task: "Proportional Search Space Reduction: A Novel Metric for Cross-View Image Geo-Location",
    },
    {
      name: "Laurent Mertens, Elahe Yargholi, Jan Van den Stock, Hans Op de Beeck and Joost Vennekens",
      task: "Abstract: Color-Dependent Prediction Stability of Popular CNN Image Classification Architectures",
    },
    {
      name: "Jonathan Aechtner, Mirela Popa and Anna Wilbik",
      task: "Assessing aggressive driving behavior using attention-based models",
    },
    {
      name: "Henry Chopp, Alicia McGeachy, Matthias Alfeld, Oliver Cossairt, Marc Walton and Aggelos Katsaggelos",
      task: "Image Processing Perspectives of X-Ray Fluorescence Data in Cultural Heritage Sciences",
    },
    {
      name: "Nassim Versbraegen, Barbara Gravel, Charlotte Nachtegael, Alexandre Renaux, Emma Verkinderen, Ann Nowé, Tom Lenaerts and Sofia Papadimitriou",
      task: "Faster and more accurate pathogenic combination predictions with VarCoPP2.0",
    },
    {
      name: "Robbe D'Hondt, Sinéad Moylett, An Goris and Celine Vens",
      task: "A Binning Approach for Predicting Long-Term Prognosis in Multiple Sclerosis",
    },
    {
      name: "Munirdin Jadikar, Martijn van Leeuwen, Thijs van Oudheusden, Sebastian Oei, Rik Kint, Bastiaan Steunenberg, Erik Ranschaert, Gerlof Bosma, Gorkem Saygili and Sharon Ong",
      task: "Deep Learning Classifiers to Reduce False Positives in Bone Lesion Segmentation from CT Scans of Multiple Myeloma",
    },
    {
      name: "Ceder Dens, Kris Laukens, Wout Bittremieux and Pieter Meysman",
      task: "The pitfalls of negative data bias for the T-cell epitope specificity challenge",
    },
    {
      name: "Marnix Suilen, Thiago D. Simão and Nils Jansen",
      task: "Safe Policy Improvement in POMDPs",
    },
    {
      task: "Training and Transferring Safe Policies in Reinforcement Learning",
      name: "Qisong Yang, Thiago D. Simão, Nils Jansen, Simon Tindemans and Matthijs T. J. Spaan",
    },
    {
      task: "Recursive Small-Step Multi-Agent A*: A Faster Exact Solver for Dec-POMDPs",
      name: "Wietze Koops, Nils Jansen, Sebastian Junges and Thiago Simão",
    },
    {
      task: "Human Recognition of Emotions Expressed by Human-like Avatars on 2D Screens",
      name: "Erik van Haeringen, Marco Otte and Charlotte Gerritsen",
    },
    {
      task: "Surrealistic-like Image Generation with Vision-Language Models",
      name: "Elif Ayten, Shuai Wang and Hjalmar Snoep",
    },

    {
      task: "Effectiveness of User States, Demographics and Traits in Persuading to Quit Smoking",
      name: "Nele Albers, Mark A. Neerincx and Willem-Paul Brinkman",
    },
    {
      task: "Determinants of physical activity behavior change in (online) interventions, and gender-specific differences: a Bayesian network model",
      name: "Simone Tummers, Arjen Hommersom, Lilian Lechner, Roger Bemelmans and Catherine Bolman",
    },
    {
      task: "Predicting Depression and Anxiety Risk in Dutch Neighborhoods from Street-View Images",
      name: "Nin Khodorivsko and Giacomo Spigler",
    },
  ];

  const dataFRI = [
    {
      name: "Igor Smit, Zaharah Bukhsh, Yingqian Zhang, Mykola Pechenizkiy",
      task: "Learning to Be Efficient and Fair for Collaborative Order Picking",
      thesis: "Thesis paper",
    },
    {
      name: "Niels Schelleman, Wouter Duivesteijn",
      task: "Exceptional In So Many Domains: Exploring Exceptional Model Mining in Unstructured Data",
      thesis: "Thesis paper",
    },
    {
      name: "Andrei C. Tociu, Yasin I. Tepeli and Joana P. Gonçalves",
      task: "Importance Weighting Domain Adaptation Beyond Specific Target Domains ",
      thesis: "Thesis paper",
    },

    {
      name: "Marko Petkovic, Vlado Menkovski",
      task: "Generative AI descriptors for precursor microRNA",
      thesis: "Thesis paper",
    },
    {
      name: "Merlijn Krale",
      task: "Active Measuring in Uncertain Environments",
      thesis: "Thesis paper",
    },
    {
      name: "Lisanne Henzen, Pascal Wiggers",
      task: "Reducing Bias in the ASR 'Whisper' Model: A Synthetic Speech Data Approach",
      thesis: "Thesis paper",
    },
    {
      name: "Oscar Oosterling",
      task: "Mitigating Intersectional Bias in Toxic Comment Classification Models through Data Balancing",
      thesis: "Thesis paper",
    },
    {
      name: "Coen Rouwmaat",
      task: "Detecting and Mitigating Goal Misgeneralisation with Logical Interpretability Tools",
      thesis: "Thesis paper",
    },
    {
      name: "Sam Vanspringel, Alexandra Cimpean, Pieter Libin, Ann Nowé",
      task: "A general purpose framework for fairness in job hiring",
      thesis: "Thesis paper",
    },
    {
      name: "Marloes Middelweerd, Wouter Merkx, Pedro Zattoni Scroccaro, Bilge Atasoy",
      task: "Learning Customer Behavior in Online Grocery Services",
      thesis: "Thesis paper",
    },
    {
      name: "Eline Bovy",
      task: "The Underlying Belief Model of Uncertain Partially Observable Markov Decision Processes",
      thesis: "Thesis paper",
    },
    {
      name: "Kristian Gogora, Tom Viering",
      task: "Assessing Convexity Characteristics of Learning Curves",
      thesis: "Thesis paper",
    },

    {
      task: "Neural Probabilistic Logic Programming in Discrete-Continuous Domains",
      name: "Lennert De Smet, Pedro Zuidberg Dos Martires, Robin Manhaeve, Giuseppe Marra, Angelika Kimmig, Luc De Raedt",
    },
    {
      task: "Rights and Practical Reasoning in Deontic Logic",
      name: "Huimin Dong, Dragan Doder, Xu Li, Réka Markovich, Leon van der Torre, Marc van Zee",
    },
    {
      task: "Reasoning About Group Responsibility for Taking Risks",
      name: "Maksim Gladyshev, Natasha Alechina, Mehdi Dastani, Dragan Doder",
    },
    {
      task: "Multi-Agent Communication using Difference Rewards Policy Gradients",
      name: "Simon Vanneste, Astrid Vanneste, Tom De Schepper, Siegfried Mercelis, Peter Hellinckx, Kevin Mets",
    },
    {
      task: "Laser Learning Environment: A new environment for coordination-critical multi-agent tasks",
      name: "Yannick Molinghen, Raphaël Avalos, Mark Van Achter, Ann Nowé, Tom Lenaerts",
    },
    {
      task: "Language Games Meet Multi-Agent Reinforcement Learning: A Case Study for the Naming Game",
      name: "Paul Van Eecke, Katrien Beuls, Jérôme Botoko Ekila, Roxana Rădulescu",
    },
    {
      task: "Continuous Communication with Factorized Policy Gradients in Multi-agent Deep Reinforcement Learning",
      name: "Changxi Zhu, Mehdi Dastani, Shihan Wang",
    },
    {
      task: "Neuro-Symbolic Procedural Semantics for Reasoning-Intensive Visual Dialogue Tasks",
      name: "Lara Verheyen, Jérôme Botoko Ekila, Jens Nevens, Paul Van Eecke, Katrien Beuls",
    },
    {
      task: "Minimalist Entity Disambiguation for Mid-Resource Languages",
      name: "Benno Kruit",
    },
    {
      task: "Enhancing Fake News Classification Through an Argumentation-Based Pipeline",
      name: "Mayla Kersten, Giovanni Sileno",
    },
    {
      task: "The Role of Personal Perspectives in Open-Domain Dialogue: Towards Enhanced Data Modelling and Long-term Memory",
      name: "Lea Krause, Selene Baez Santamaria, Lucia Donatelli, Piek Vossen",
    },
    {
      task: "Why do penguins fly? Explaining Boolean Classifiers with Non-monotonic Background Theories",
      name: "Tjitze Rienstra",
    },
    {
      task: "BELLATREX: Building Explanations through a LocaLly AccuraTe Rule EXtractor",
      name: "Klest Dedja, Felipe Kenji Nakano, Konstantinos Pliakos, Celine Vens",
    },
    {
      task: "Motivating explanations in Bayesian networks using MAP-independence",
      name: "Johan Kwisthout",
    },
    {
      task: "Defining the Optimal Degree of Abstraction in Explanations with Kolmogorov complexity",
      name: "Jan Lemeire, Stefan Buijsman",
    },
    {
      task: "Vectorizing Timestamps by means of a Variational Autoencoder",
      name: "Gideon Rouwendaal, Xander Wilcke",
    },
    {
      task: "Direct Exoplanet Detection Using L1 Norm Low-Rank Approximation",
      name: "Hazan Daglayan, Simon Vary, Valentin Leplat, Nicolas Gillis, Pierre Antoine Absil",
    },
    {
      task: "GroupEnc: encoder with group loss for global structure preservation",
      name: "David Novak, Sofie Van Gassen, Yvan Saeys",
    },
    {
      task: '"How sweet are your strawberries?": predicting sugariness using non-destructive and affordable hardware',
      name: "Junhan Wen, Thomas Abeel, Mathijs de Weerdt",
    },
    {
      task: "Forecasting Train Arrival Delays on the Polatlı – Eskişehir High-Speed Line in Turkey",
      name: "Özgül Ardıç",
    },
    {
      task: "Learning to Adapt Genetic Algorithms for Multi-Objective Flexible Job Shop Scheduling Problems",
      name: "Robbert Reijnen, Zaharah Bukhsh, Yingqian Zhang, Mateusz Guzek",
    },
    {
      task: "Machine Learning Applications in Route Planning for Attended Home Delivery",
      name: "Thomas R. Visser, Ruggiero Seccia, Wouter Merkx, Wouter Kool, Leendert Kok",
    },
    {
      task: "The EURO Meets NeurIPS 2022 Vehicle Routing Competition",
      name: "Wouter Kool, Laurens Bliek, Danilo Numeroso, Yingqian Zhang, Tom Catshoek, Kevin Tierney, Thibaut Vidal, Joaquim Gromicho",
    },
    {
      task: "Forecast Reconciliation for Vaccine Supply Chain Optimization",
      name: "Bhanu Angam, Alessandro Beretta, Eli De Poorter, Matthieu Duvinage, Daniel Peralta",
    },
    {
      task: "Improving Performance of Heart Rate Time Series Classification by Grouping Subjects",
      name: "Michael Beekhuizen, Arman Naseri, David Tax, Ivo van der Bilt, Marcel Reinders",
    },
    {
      task: "Predicting Station-Level Bike Demand in Bike-Sharing Systems Using a Hybrid Neural Network",
      name: "Carlijn Jurriaans, Stijn Rotman, Eva Vanmassenhove",
    },
    {
      task: "Model-Based Clustering of Individuals' Ecological Momentary Assessment Data for Improving Forecasting Performance",
      name: "Mandani Ntekouli, Gerasimos Spanakis, Lourens Waldorp, Anne Roefs",
    },
    {
      task: "Retrieval-Based Question Answering with Passage Expansion Using a Knowledge Graph",
      name: "Yiming Xu, Jan-Christoph Kalo, Benno Kruit",
    },
    {
      task: "Relevance Feedback Strategies for Recall-Oriented Neural Information Retrieval",
      name: "Timo Kats, Peter van der Putten, Jan Scholtes",
    },
    {
      task: "The Effectiveness of Different Group Recommendation Strategies for Different Group Compositions",
      name: "Francesco Barile, Tim Draws, Oana Inel, Alisa Rieger, Amir Ebrahimi Fard, Rishav Hada, Nava Tintarev",
    },
    {
      task: "Potential Factors Leading to Popularity Unfairness in Recommender Systems: A User-Centered Analysis",
      name: "Masoud Mansoury, Finn Duijvestijn, Imane Mourabet",
    },
  ];

  return (
    <div className="registration">
      <Navigation2 />
      <div className="pp-heading">
        <h2>Posters</h2>
        <div className="pr-buttons">
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("WED 8 NOV")}
          >
            <b>WED 8 NOV</b>
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("THU 9 NOV")}
          >
            <b>THU 9 NOV</b>
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("FRI 10 NOV")}
          >
            <b>FRI 10 NOV</b>
          </button>
        </div>
        <div className="posters">
          {selectedDate === "WED 8 NOV" && (
            <>
              <table className="table table-bordered table-dark custom-table">
                <thead></thead>
                <tbody>
                  {dataWED.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.task}</td>
                      <td>{item.thesis}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {selectedDate === "THU 9 NOV" && (
            <>
              <table className="table table-bordered table-dark custom-table">
                <thead></thead>
                <tbody>
                  {dataTHU.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.task}</td>
                      <td>{item.thesis}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {selectedDate === "FRI 10 NOV" && (
            <>
              <table className="table table-bordered table-dark custom-table">
                <thead></thead>
                <tbody>
                  {dataFRI.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.task}</td>
                      <td>{item.thesis}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </div>
  );
};

export default Posters;
