import "../Header/Header.css";
import bnaiclogo from "../../assets/BNAIC BeNeLearn 01-04.png";
import { GoLocation } from "react-icons/go";
import { BsCalendarDate } from "react-icons/bs";
import "aos/dist/aos.css";
import React, { useState, useEffect } from "react";
import PDF from "../../assets/BNAIC_BeNeLearn_2023_Programme_Booklet.pdf";

const Header = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop;
      const percent = (scrollTop / scrollHeight) * 100;
      setScrollPercentage(percent);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const dateStyle = {
    fontSize: "18px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bolder",
    letterSpacing: "1px",
    color:"white",
    textTransform:"uppercase"
  };

  const BookletStyle = {
    fontSize: "18px",
    fontFamily: "Montserrat, sans-serif",
    fontWeight: "bolder",
    letterSpacing: "1px",
    color:"#00a6d6",
    textTransform:"uppercase"
  };

  return (
    <div className="mainforall">
      <section
        className="welcomepage"
        style={{ opacity: scrollPercentage === 100 ? 0 : 1 }}
      >
        <div
          data-aos="zoom-in"
          data-aos-easing="ease-in"
          data-aos-duration="2000"
          className="hero-text"
        >
          <img alt="header-logo-img" src={bnaiclogo} />
          <p className="lato">
            Joint International Scientific Conferences on AI and Machine
            Learning
          </p>
          <div className="r-icons">
            <a
              href="https://maps.app.goo.gl/pJZhY9WWgNiZCiSX9"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <GoLocation /> {""} Delft, The Netherlands{" "}
            </a>

            <p style={dateStyle}>
              {" "}
              <BsCalendarDate /> {""} Wed 8 Nov - Fri 10 Nov 2023{" "}
            </p>

            <p style={BookletStyle}>
            The BNAIC/BeNeLearn Programme Booklet is available here:
            <br/>
            <a href={PDF}  download>
              Download Booklet
            </a>
          </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Header;
