import React from "react";
import "../Registration/Registration.css";
import Navigation from "../Navigation/Navigation";
import "./Program.css";
import Footer2 from "../FooterForOthers/Footer2";
import { GoLocation } from "react-icons/go";
import { AiOutlineLink } from "react-icons/ai";
import { useState } from "react";
import PDF from "../../assets/BNAIC_BeNeLearn_2023_Programme_Booklet.pdf";

import { HashLink } from "react-router-hash-link";
const Program = () => {
  const [selectedDate, setSelectedDate] = useState("FRI 10 NOV");

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const aStyle = {
    color: "black",
  };

  return (
    <div className="registration">
      <Navigation />

      <div className="att-heading">
        <h1>Programme</h1>
        <div className="pr-buttons">
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("TUE 7 NOV")}
          >
            <b>TUE 7 NOV</b>
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("WED 8 NOV")}
          >
            <b>WED 8 NOV</b>
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("THU 9 NOV")}
          >
            <b>THU 9 NOV</b>
          </button>
          <button
            className="btn btn-outline-info"
            onClick={() => handleDateClick("FRI 10 NOV")}
          >
            <b>FRI 10 NOV</b>
          </button>
        </div>

        <div>
          <table className="table table-sm">
            <thead></thead>
            <tbody>
              {selectedDate === "TUE 7 NOV" && (
                <>
                  <th className="bg-warning" scope="col">
                    TUE 7 NOV
                  </th>
                  <th className="bg-warning" scope="col"></th>
                  <th className="bg-warning" scope="col">
                    Location
                  </th>
                  <th className="bg-warning" scope="col">
                    Registration
                  </th>
                  <tr className="bg-info">
                    <th scope="row">13:00 -18:30</th>
                    <td>
                      {" "}
                      <b>ELLIS Benelux PhD event</b>
                    </td>
                    <td>
                      <b>
                        <a
                          style={{ font: "bolder", color: "#212529" }}
                          href="https://maps.app.goo.gl/o2S146B2D5S5gn598"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <GoLocation /> Mondai, House of AI
                        </a>
                      </b>
                    </td>
                    <td>
                      <b>
                        <a
                          style={{ font: "bolder", color: "#212529" }}
                          href="https://www.aanmelder.nl/148002/subscribe"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <AiOutlineLink /> Seperate registration needed
                        </a>
                      </b>
                    </td>
                  </tr>
                </>
              )}
              {selectedDate === "WED 8 NOV" && (
                <>
                  <th className="bg-warning" scope="col">
                    WED 8 NOV
                  </th>
                  <tr className="bg-info">
                    <th scope="row">09:00-09:15</th>
                    <td>
                      {" "}
                      <b>opening</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">09:15-10:00</th>
                    <td>
                      <b>best thesis papers</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">10:00-10:30</th>
                    <td>
                      <b> poster spotlight</b>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">10:30-11:00</th>
                    <td>
                      <b>coffee + posters</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">11:00-12:00</th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/invited-talks#talkjan"
                      >
                        <b>
                          <b>invited talk - Jan-Willem van de Meent</b>
                        </b>
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">12:00-13:00</th>
                    <td>
                      <b>lunch + posters</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">13:00-14:30</th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/papers-to-sessions/#talks1"
                      >
                        <b> contributed talks #1</b>{" "}
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">14:30-15:30</th>
                    <td>
                      <b>coffee + posters</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">15:30-17:30</th>
                    <td>
                      <a style={aStyle} href="/industrial-session">
                        <b>industry panel + demos </b>
                      </a>

                      <b>
                        <GoLocation /> NextDelft
                      </b>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">17:30-19:00</th>

                    <td>
                      <b>reception </b>
                      <b>
                        <GoLocation /> NextDelft
                      </b>
                    </td>
                  </tr>
                </>
              )}
              {selectedDate === "THU 9 NOV" && (
                <>
                  <th className="bg-warning" scope="col">
                    THU 9 NOV
                  </th>
                  <tr className="bg-info">
                    <th scope="row">09:00-10:00</th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/papers-to-sessions#talks2"
                      >
                        <b> contributed talks #2 + EurAI session</b>{" "}
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">10:00-10:30</th>
                    <td>
                      <b> poster spotlight</b>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">10:30-11:00</th>
                    <td>
                      <b>coffee + posters</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">11:00-12:00</th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/papers-to-sessions#talks3"
                      >
                        <b>
                          {" "}
                          <b>contributed talks #3 + industry talk</b>
                        </b>{" "}
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">12:00-13:00</th>
                    <td>
                      <b>lunch + BNVKI general assembly</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">13:00-14:30</th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/papers-to-sessions#talks4"
                      >
                        <b>
                          {" "}
                          <b>contributed talks #4</b>
                        </b>{" "}
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">14:30-15:30</th>
                    <td>
                      <b>coffee + posters</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">15:30-17:00</th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/papers-to-sessions#talks5"
                      >
                        <b>
                          {" "}
                          <b>contributed talks #5</b>
                        </b>{" "}
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">17:00-17:30</th>
                    <td>
                      <b> coffee</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">17:30-18:30</th>
                    <td>
                      <b> FACt session</b>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">19:30</th>
                    <td>
                      <b>conference dinner </b>
                      <b>
                        <GoLocation /> Heinde & Ver
                      </b>
                    </td>
                  </tr>
                </>
              )}
              {selectedDate === "FRI 10 NOV" && (
                <>
                  <th className="bg-warning" scope="col">
                    FRI 10 NOV
                  </th>
                  <tr className="bg-info">
                    <th scope="row">09:00-10:30 </th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/papers-to-sessions#talks6"
                      >
                        <b>
                          <b>contributed talks #6</b>
                        </b>
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">10:30-11:00</th>
                    <td>
                      <b>poster spotlight</b>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">11:00-11:30</th>
                    <td>
                      <b>coffee + posters</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">11:30-12:30 </th>
                    <td>
                      <HashLink
                        style={aStyle}
                        smooth
                        to="/invited-talks#talkann"
                      >
                        <b>
                          <b>invited talk - Ann Nowé</b>
                        </b>
                      </HashLink>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">12:30-13:30</th>
                    <td>
                      <b>lunch + posters</b>
                    </td>
                  </tr>
                  <tr className="bg-info">
                    <th scope="row">13:30-14:00</th>
                    <td>
                      <b>awards + networking</b>
                    </td>
                  </tr>
                  <tr className="bg-warning">
                    <th scope="row">14:00-14:30</th>
                    <td>
                      <b>coffee + drinks</b>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>

        <div className="excel">
          <p>
            <b>The BNAIC/BeNeLearn Programme Booklet is available here: </b>
            <br/>

            <a href={PDF} download>
              Download Booklet
            </a>
          </p>

          <p>
            <b>Papers to sessions full programme: </b>

            <a href="/papers-to-sessions"> Papers to sessions</a>
          </p>

          <p>
            <b>Poster pitches full programme: </b>

            <a href="/ppitches"> Poster pitches</a>
          </p>
          <p>
            <b>Posters full programme: </b>

            <a href="/posters"> Posters</a>
          </p>
          <p>
            <b>Industrial Session full programme: </b>

            <a href="/industrial-session"> Industrial Session</a>
          </p>
          <p>
            <b>Invited talks full programme:</b>

            <a href="/invited-talks"> Invited talks</a>
          </p>
          <p>
            <b>Preproceedings full programme:</b>

            <a href="/pre-proceedings"> Preproceedings</a>
          </p>
        </div>
      </div>
      <div className="footer-att">
        <Footer2 />
      </div>
    </div>
  );
};

export default Program;
