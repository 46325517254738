export const gChair = [
    {
        id: 1,
        "name": "Bilge Atasoy",
        "job": "TU Delft (3mE)",
        image:
        "OrganizersImages/bilge.jpg"
      },
    {
      id: 2,
      name: "Neil Yorke-Smith",
      job: "TU Delft (EEMCS)",
      image:
      "OrganizersImages/neil.png"
      
    }
  ];

  export const pChair = [
    {
        id: 1,
        "name": "Manon Kok",
        "job": "TU Delft (3mE)",
        image:
        "OrganizersImages/manon.jpg"
      },
    {
      id: 2,
      name: "Frans Oliehoek",
      job: "TU Delft (EEMCS)",
      image:
      "OrganizersImages/Frans_Oliehoek.jpg"
      
    },
    {
      id: 3,
      name: "Sicco Verwer",
      job: "TU Delft (EEMCS)",
      image:
      "OrganizersImages/Sicco.jpg"
      
    }
  ];

  export const pwChair = [
    {
        id: 1,
        "name": "Zlatan Ajanović",
        "job": "TU Delft (3mE)",
        image:
        "OrganizersImages/zlatan.jpg"
      }
  ];

  export const sChair = [
    {
        id: 1,
        "name": "Roel Dobbe",
        "job": "TU Delft (TPM)",
        image:
        "OrganizersImages/womenplacehol.jpg"
      }
  ];

  export const loChair = [
    {
        id: 1,
        "name": "Pedro Vergara Barrios",
        "job": "TU Delft (EEMCS)",
        image:
        "OrganizersImages/pedro1.png"
      },
      {
        id: 2,
        "name": "Lolke Boonstra",
        "job": "TU Delft (ICT)",
        image:
        "OrganizersImages/lolke.jpg"
      },
    
    {
      id: 3,
      name: "Issa Hanou",
      job: "TU Delft",
      image:
      "OrganizersImages/Issa.jpg"
      
    }
  ];

  export const leChair = [
    {
        id: 1,
        "name": "Patty Bokop-van der Stap",
        "job": "TU Delft (3mE)",
        image:
        "OrganizersImages/bokop.jpg"
      },
    {
      id: 2,
      name: "Simon Goede",
      job: "Mondai",
      image:
      "OrganizersImages/womenplacehol.jpg"
      
    },
    {
      id: 3,
      name: "Joost Poort",
      job: "Mondai",
      image:
      "OrganizersImages/womenplacehol.jpg"
      
    }
  ];